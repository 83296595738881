export const navbar = [
  {
    id: 1,
    text: "home",
    path: "/home",
  },

  {
    id: 2,
    text: "sobre",
    path: "/sobre",
  },

  {
    id: 3,
    text: "loja",
    path: "/loja",
  },

  {
    id: 4,
    text: "galerias",
    path: "/galerias",
  },

  {
    id: 5,
    text: "throwback",
    path: "/throwback",
  },

  {
    id: 6,
    text: "tags",
    path: "/tags",
  },

  {
    id: 7,
    text: "contatos",
    path: "/contatos",
  },
];

export const hero = [
  {
    id: 1,
    categoryID: 1,
    cover: "/images/hero/spray.png",
    name: "Latas",
    items: "5",
  },
  {
    id: 2,
    categoryID: 2,
    cover: "/images/hero/marker.png",
    name: "Marcadores",
    items: "12",
  },
  {
    id: 3,
    categoryID: 3,
    cover: "/images/hero/acessories.png",
    name: "Acessórios",
    items: "12",
  },
  {
    id: 4,
    categoryID: 4,
    cover: "/images/hero/recharge.png",
    name: "Recargas",
    items: "15",
  },
  {
    id: 5,
    categoryID: 5,
    cover: "/images/hero/cancap.png",
    name: "Caps",
    items: "7",
  },
];

export const products = [
  {
    id: 1,
    categoryID: 1,
    brandID: 1,
    cover: "/images/shop/latas/mtn-94.png",
    title: "94",
    author: "Montana",
    price: 4.45,
    category: "cans",
    qty: 0,
    desc: "MTN 94 é provavelmente o aerossol mais versátil do mercado. Graças à sua extensa gama de cores e capacidade de secagem rápida, é uma ferramenta adequada para praticamente qualquer tipo de trabalho criativo que requer um acabamento mate.",
  },
  {
    id: 2,
    categoryID: 1,
    brandID: 2,
    cover: "/images/shop/latas/hardcore.png",
    title: "Hardcore",
    author: "Montana",
    price: 3.95,
    category: "cans",
    qty: 0,
    desc: "O formato que revolucionou o mundo dos graffitis continua a sofrer uma evolução constante para satisfazer as exigências do mercado. Graças à experiência de Montana Colors no campo, hardcore compreende o máximo desenvolvimento de tinta aerossol, tornando-se uma das tintas de spray de brilho mais bem executantes do mercado.",
  },

  {
    id: 3,
    categoryID: 1,
    brandID: 16,
    cover: "/images/shop/latas/nbq_slow.png",
    title: "Slow",
    author: "NBQ",
    price: 3.4,
    category: "cans",
    qty: 0,
    desc: "148 cores 400ml baixa pressão, secagem rápida, acabamento mate e alta cobertura",
  },

  {
    id: 4,
    categoryID: 1,
    brandID: 17,
    cover: "/images/shop/latas/nbq_eternal.png",
    title: "Eternal",
    author: "NBQ",
    price: 3.6,
    category: "cans",
    qty: 0,
    desc: "Alta pressão, Secagem ultrarrápida, Excelente resistência ao ar livre",
  },

  {
    id: 5,
    categoryID: 1,
    brandID: 3,
    cover: "/images/shop/latas/megacolors.png",
    title: "Mega Colors",
    author: "Montana",
    price: 5.4,
    category: "cans",
    qty: 0,
    desc: "Mega Colors são a evolução da clássica lata Montana Colors Mega. Esta tinta possui praticamente as mesmas propriedades da Hardcore, mas vem no formato de 600 ml. Isso se traduz não só em maior quantidade de tinta, mas também em uma potência de pulverização mais potente, que projeta um contorno muito mais amplo devido ao seu sistema de válvulas.",
  },

  {
    id: 6,
    categoryID: 1,
    brandID: 4,
    cover: "/images/shop/latas/megasilver.png",
    title: "Mega Silver",
    author: "Montana",
    price: 5.4,
    category: "cans",
    qty: 0,
    desc: "Tinta spray cromada acrílica de 600 ml com acabamento brilhante intenso. Mega Silver seca muito rápido e aproveita ao máximo a potência de sua válvula para descarregar a tinta em alta velocidade, deixando para trás uma linha de gordura com a camada ideal de tinta.",
  },

  {
    id: 7,
    categoryID: 1,
    brandID: 5,
    cover: "/images/shop/latas/nitro2g.png",
    title: "Nitro 2G",
    author: "Montana",
    price: 5.5,
    category: "cans",
    qty: 0,
    desc: "Apresentando a tecnologia do Nitro 2G em um formato maior. Além de ser uma tinta no formato de 500 ml, de secagem rápida e ótima cobertura, a Nitro 2G também está disponível em 10 cores diferentes.",
  },
  {
    id: 8,
    categoryID: 1,
    brandID: 6,
    cover: "/images/shop/latas/maximo.png",
    title: "Maximo",
    author: "Montana",
    price: 6.8,
    category: "cans",
    qty: 0,
    desc: "MAXIMO é mais um dos produtos históricos da Montana Colors, sendo o maior tamanho disponível por muitos anos. Está disponível em três cores básicas: branco, preto e prata.",
  },
  {
    id: 9,
    categoryID: 1,
    brandID: 6,
    cover: "/images/shop/latas/madmax.png",
    title: "Mad Maxxx",
    author: "Montana",
    price: 7.75,
    category: "cans",
    qty: 0,
    desc: "MTN MAD MAXXX é uma poderosa tinta spray de 750 ml que projeta uma linha cinzelada de cerca de 25-30 cm de largura. Projetado para preencher grandes superfícies em alta velocidade, também pode ser usado para pintar tipografia de tamanho enorme.",
  },
  {
    id: 10,
    categoryID: 1,
    brandID: 7,
    cover: "/images/shop/latas/krink.png",
    title: "Krink K-750",
    author: "Montana",
    price: 7.75,
    category: "cans",
    qty: 0,
    desc: "O K-750 é uma tinta spray Montana Colors desenvolvida em colaboração com a renomada marca de marcadores e tintas de Nova York, KRINK. A válvula e a fórmula liberam um poderoso jato que permite pulverizar a uma distância de mais de um metro da parede.",
  },

  {
    id: 11,
    categoryID: 1,
    brandID: 8,
    cover: "/images/shop/latas/micro.png",
    title: "Micro",
    author: "Montana",
    price: 2.5,
    category: "cans",
    qty: 0,
    desc: "Inspirada no antigo modelo Hardcore, esta é a menor tinta spray do mercado.",
  },

  {
    id: 12,
    categoryID: 1,
    brandID: 9,
    cover: "/images/shop/latas/pocket.png",
    title: "Pocket",
    author: "Montana",
    price: "TBD",
    category: "cans",
    qty: 0,
    desc: "Uma tinta spray de bolso disponível em várias cores.",
  },

  {
    id: 13,
    categoryID: 1,
    brandID: 10,
    cover: "/images/shop/latas/alien.png",
    title: "Alien",
    author: "Montana",
    price: "TBD",
    category: "cans",
    qty: 0,
    desc: "Uma tinta acetinada de baixa pressão desenvolvida para trabalhos artísticos. Seu formato de 250 ml e Pro Cap proporcionam maior precisão, tornando-a uma tinta spray extremamente manejável de usar. Além disso, suas características de válvula e viscosidade da tinta o tornam ideal para trabalhar em detalhes e obter linhas finas.",
  },

  {
    id: 14,
    categoryID: 1,
    brandID: 11,
    cover: "/images/shop/latas/pack.png",
    title: "Workshop Pack 16",
    author: "Montana",
    price: 70.95,
    category: "cans",
    qty: 0,
    desc: "Cádmio Amarelo Médio / Azo Laranja / Vermelho Naftol / Rosa Quinacridona / Verde Turquesa / Verde Claro Brilhante / Verde Brilhante / Amarelo Nápoles / Quinacridona Magenta / Azul Violeta / Phthalo Blue Light / Azul da Prússia / Sienna Bruto / Cinza Neutro / Branco Titânio / Negro Carbono",
  },

  {
    id: 15,
    categoryID: 2,
    brandID: 12,
    cover: "/images/shop/marcadores/acrylic-marcador-30.png",
    title: "Marcador Acrílico 30mm",
    author: "Montana",
    price: 6.95,
    category: "markers",
    qty: 0,
    desc: "Marcador à base de água com ponta de 30 mm. Disponível em 4 cores. Recarregáveis. Ponta substituível.",
  },

  {
    id: 16,
    categoryID: 2,
    brandID: 12,
    cover: "/images/shop/marcadores/acrylic-marcador-50.png",
    title: "Marcador Acrílico 50mm",
    author: "Montana",
    price: 7.5,
    category: "markers",
    qty: 0,
    desc: "Marcador à base de água com ponta de 50 mm. Disponível em 4 cores. Recarregáveis. Ponta substituível.",
  },

  {
    id: 17,
    categoryID: 2,
    brandID: 12,
    cover: "/images/shop/marcadores/squeezer_grog.png",
    title: "Squeezer Grog 25 FMP",
    author: "Grog",
    price: 8.4,
    category: "markers",
    qty: 0,
    desc: "O Squeezer 25 FMP é simplesmente ótimo. A ponta redonda FLOWTEX de 25 mm, em combinação com um corpo macio, descarrega grandes quantidades de tinta enquanto você escreve com ela. O Squeezer 25 FMP vem com 100 ml de FULL METAL PAINT. Pense grande.",
  },

  {
    id: 18,
    categoryID: 4,
    brandID: 13,
    cover: "/images/shop/shops-11.png",
    title: "Grog Buff Proof Ink",
    author: "Grog",
    price: 8.95,
    category: "refill",
    qty: 0,
    desc: "Grog Ink BPI, abreviação de Buff Proof Ink, é uma tinta permanente que penetra profunda e permanentemente na maioria das áreas (particularmente áreas porosas). Tinta translúcida muito líquida, garante um fluxo constante para um acabamento ideal. Secagem mais rápida e pigmentos resistentes aos raios UV. Refil 200ml para todos os marcadores, especialmente recomendado para espremedores Grog, Krink e OTR.",
  },

  {
    id: 19,
    categoryID: 4,
    brandID: 13,
    cover: "/images/shop/shops-12.png",
    title: "Nero D'inferno",
    author: "Nero",
    price: 8.5,
    category: "refill",
    qty: 0,
    desc: "1000 ml. É um clássico das tintas para recarregar. Muito potente em mármores, especial para marcadores de tinta à base de álcool.",
  },

  {
    id: 20,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/astrofat.png",
    title: "Astro fat Caps",
    author: "Montana",
    price: 0.25,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 21,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/pinkfat.png",
    title: "Fat Pink Caps",
    author: "Montana",
    price: 0.25,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 22,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/hardcore-medium.png",
    title: "Hardcore Medium Caps",
    author: "Montana",
    price: 0.25,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 23,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/hardcore-fat-cap.png",
    title: "Hardcore Fat Caps",
    author: "Montana",
    price: 0.35,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 24,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/lego-cap.png",
    title: "Lego Caps",
    author: "Montana",
    price: 0.35,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 25,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/hardcore-medium.png",
    title: "Hardcore Medium Caps",
    author: "Montana",
    price: 0.25,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 26,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/ny-fat.png",
    title: "NY Fat Caps",
    author: "Montana",
    price: 0.35,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 27,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/pocket-fat.png",
    title: "Pocket Fat Caps",
    author: "Montana",
    price: 0.25,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 28,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/pro-cap.png",
    title: "Pro Caps",
    author: "Montana",
    price: 0.35,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 29,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/superskinny.png",
    title: "Super Skinny Caps",
    author: "Montana",
    price: 0.35,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 30,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/transversal.png",
    title: "Transversal Caps",
    author: "Montana",
    price: 0.35,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 31,
    categoryID: 5,
    brandID: 14,
    cover: "/images/shop/caps/universal.png",
    title: "Universal Caps",
    author: "Montana",
    price: 0.25,
    category: "caps",
    qty: 0,
    desc: "",
  },

  {
    id: 32,
    brandID: 15,
    cover: "/images/shop/acessorios/handcleaner.png",
    title: "Hand Cleaner",
    author: "Montana",
    price: 0.2,
    category: "acessorios",
    qty: 0,
    desc: "Toalhetes húmidos especialmente concebidos para remover manchas de tinta e sujidade das mãos de forma rápida e eficaz.",
  },

  {
    id: 33,
    categoryID: 3,
    brandID: 15,
    cover: "/images/shop/acessorios/mtn-pro-gloves.png",
    title: "Luvas Pano",
    author: "Montana",
    price: 2.9,
    category: "acessorios",
    qty: 0,
    desc: "Luvas técnicas para segurança e proteção.",
  },

  {
    id: 34,
    categoryID: 3,
    brandID: 15,
    cover: "/images/shop/acessorios/nitril_gloves.png",
    title: "Luvas Descartáveis",
    author: "Montana",
    price: 0.25,
    category: "acessorios",
    qty: 0,
    desc: "Luvas descartáveis ​​de alta qualidade. Disponível em 3 tamanhos: M, L e XL.",
  },

  {
    id: 35,
    categoryID: 3,
    brandID: 15,
    cover: "/images/shop/acessorios/mute.png",
    title: "Mute",
    author: "Montana",
    price: 5.49,
    category: "acessorios",
    qty: 0,
    desc: "Dispositivo magnético que mantém a bola de mistura no lugar para evitar o ruído da lata de spray.",
  },
];
