export const images = [
  {
    id: "1",
    name: "Artigos em Resina",
    cover: "images/gallery/art-1.png",
    author: "stay_up_graffiticrafts",
    category: "art",
    price: "sob consulta",
    desc: "",
    instagram: "https://www.instagram.com/stay_up_graffiticrafts/",
  },

  {
    id: "2",
    name: "Artigos em Resina",
    cover: "images/gallery/art-2.png",
    author: "stay_up_graffiticrafts",
    category: "art",
    price: "sob consulta",
    desc: "",
    instagram: "https://www.instagram.com/stay_up_graffiticrafts/",
  },

  {
    id: "3",
    name: "Quadro Flamingo",
    cover: "images/gallery/quadro10.png",
    author: "RYTH",
    category: "art",
    price: "sob consulta",
    desc: "",
    instagram: "https://www.instagram.com/Hectorperez494/",
  },

  {
    id: "4",
    name: "Quadro Peixe",
    cover: "images/gallery/quadro9.png",
    author: "RYTH",
    category: "art",
    price: "sob consulta",
    desc: "",
    instagram: "https://www.instagram.com/Hectorperez494/",
  },

  {
    id: "5",
    name: "Quadro Batman",
    cover: "images/gallery/art-5.png",
    author: "VEMS",
    category: "art",
    price: "sob consulta",
    desc: "",
    instagram: "https://www.instagram.com/vems_artwork/",
  },

  {
    id: "6",
    name: "172 Krew",
    cover: "images/gallery/172.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "7",
    name: "Rosto artístico",
    cover: "images/gallery/rosto.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "Apenas para exposição",
    instagram: "",
  },

  {
    id: "8",
    name: "Body paint",
    cover: "images/gallery/bodypaint.png",
    author: "Syrena",
    category: "art",
    price: "Apenas para exposição",
    desc: "Apenas para exposição",
    instagram: "https://www.instagram.com/syrena_tattoos/",
  },

  {
    id: "9",
    name: "Desenhos",
    cover: "images/gallery/desenho1.png",
    author: "Gone",
    category: "art",
    price: "Apenas para exposição",
    desc: "Apenas para exposição",
    instagram: "https://www.instagram.com/gonebomber/",
  },

  {
    id: "10",
    name: "Quadros",
    cover: "images/gallery/quadro1.png",
    author: "Gone",
    category: "art",
    price: "Apenas para exposição",
    desc: "Apenas para exposição",
    instagram: "https://www.instagram.com/gonebomber/",
  },

  {
    id: "11",
    name: "Quadros",
    cover: "images/gallery/quadro2.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "12",
    name: "Quadros",
    cover: "images/gallery/quadro4.png",
    author: "Gone",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "https://www.instagram.com/gonebomber/",
  },

  {
    id: "13",
    name: "Quadros",
    cover: "images/gallery/quadro4.png",
    author: "Gone",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "https://www.instagram.com/gonebomber/",
  },

  {
    id: "14",
    name: "Quadros",
    cover: "images/gallery/quadro5.png",
    author: "Surge",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "https://www.instagram.com/apenassurge/",
  },

  {
    id: "15",
    name: "Artigos em Resina",
    cover: "images/gallery/quadro6.png",
    author: "stay_up_graffiticrafts",
    category: "art",
    price: "sob consulta",
    desc: "",
    instagram: "https://www.instagram.com/stay_up_graffiticrafts/",
  },

  {
    id: "16",
    name: "Quadros",
    cover: "images/gallery/quadro7.png",
    author: "Ked",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "17",
    name: "Quadros",
    cover: "images/gallery/quadro8.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "18",
    name: "Quadros",
    cover: "images/gallery/quadro11.png",
    author: "Gone",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "https://www.instagram.com/gonebomber/",
  },

  {
    id: "19",
    name: "Quadros",
    cover: "images/gallery/quadro11.png",
    author: "Nop",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "20",
    name: "Sk8",
    cover: "images/gallery/sk8.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "21",
    name: "Stickers",
    cover: "images/gallery/stickers.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },

  {
    id: "22",
    name: "Tags",
    cover: "images/gallery/tags.png",
    author: "",
    category: "art",
    price: "Apenas para exposição",
    desc: "",
    instagram: "",
  },
];

export const projects = [
  {
    id: 1,
    name: "Escola do Marco",
    cover: "images/projetos/escola_marco.png",
    desc: "Projeto Realizado na escola EB Marco",
    url: "https://www.ae1marco.pt/",
  },

  {
    id: 2,
    name: "Escola do Marco",
    cover: "images/projetos/escola_marco2.png",
    desc: "Projeto Realizado na escola EB Marco",
    url: "https://www.ae1marco.pt/",
  },

  {
    id: 3,
    name: "C. A. Adriano Correia de Oliveira",
    cover: "images/projetos/adriano_correia.png",
    desc: "Projeto Realizado no Centro Artístico Adriano Correia de Oliveira",
    url: "https://adrianocorreiadeoliveira.org/",
  },

  {
    id: 4,
    name: "C. A. Adriano Correia de Oliveira",
    cover: "images/projetos/adriano_correia2.png",
    desc: "Projeto Realizado no Centro Artístico Adriano Correia de Oliveira",
    url: "https://adrianocorreiadeoliveira.org/",
  },

  {
    id: 5,
    name: "EB Adriano Correia de Oliveira",
    cover: "images/projetos/adriano_correia3.png",
    desc: "Projeto Realizado na EB Adriano Correia de Oliveira",
    url: "https://www.aegaianascente.pt/escolas02.php",
  },

  {
    id: 6,
    name: "EB Adriano Correia de Oliveira",
    cover: "images/projetos/adriano_correia4.png",
    desc: "Projeto Realizado na EB Adriano Correia de Oliveira",
    url: "https://www.aegaianascente.pt/escolas02.php",
  },
];

export const services = [
  {
    id: 1,
    name: "Restauros",
    cover: "images/services/restauro.png",
    desc: "Serviço de Restauro em Arte Urbana",
  },

  {
    id: 2,
    name: "Workshops",
    cover: "images/services/workshop_graffiti.png",
    desc: "Aula Aberta e Workshop de Graffiti",
  },

  {
    id: 3,
    name: "Entregas",
    cover: "images/services/entregas.png",
    desc: "Serviço de entregas (Vila Nova de Gaia e Portugal Continental)",
  },

  {
    id: 4,
    name: "Multimedia",
    cover: "images/services/criacao_multimedia.png",
    desc: "Produção de conteúdos, vídeos, sites, fotografias e podcasts",
  },

  {
    id: 5,
    name: "Fotografias",
    cover: "images/services/cobertura.png",
    desc: "Cobertura fotográfica e criações de clipes de bandas",
  },

  {
    id: 6,
    name: "Murais de Graffiti",
    cover: "images/services/producao_murais.png",
    desc: "Produção de Murais de Graffiti",
  },

  {
    id: 7,
    name: "Peças Institucionais",
    cover: "images/services/pecas_institucionais.png",
    desc: "Produção de peças artísticas institucionais",
  },

  {
    id: 8,
    name: "Quartos Personalizados",
    cover: "images/services/pintura.png",
    desc: "Pinturas temáticas para interiores",
  },
];

export const participation = [
  {
    id: 1,
    name: "Oldschool Beat Box League 2018",
    cover: "images/participacoes/bbox1.png",
    desc: "Campeonato Nacional de Beatbox no Porto",
  },

  {
    id: 2,
    name: "Writers united in PARQ 2018",
    cover: "images/participacoes/aveiro.png",
    desc: "Festival Aveiro",
  },

  {
    id: 3,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop1.png",
    desc: "",
  },

  {
    id: 4,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop2.png",
    desc: "",
  },

  {
    id: 5,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop3.png",
    desc: "",
  },

  {
    id: 6,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop4.png",
    desc: "",
  },

  {
    id: 7,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop5.png",
    desc: "",
  },

  {
    id: 8,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop6.png",
    desc: "com o nosso Amigo e Dj Pico90",
  },

  {
    id: 9,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop7.png",
    desc: "",
  },

  {
    id: 10,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop8.png",
    desc: "",
  },

  {
    id: 11,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop9.png",
    desc: "",
  },

  {
    id: 12,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop10.png",
    desc: "",
  },

  {
    id: 13,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop11.png",
    desc: "Yng Pako 5dm",
  },

  {
    id: 14,
    name: "Liga Multiversus",
    cover: "images/participacoes/hiphop12.png",
    desc: "Bi jon 5dm",
  },

  {
    id: 15,
    name: "Oldschool Projeto Mar",
    cover: "images/participacoes/mar1.png",
    desc: "Projeto Mar em VNG",
  },
];

export const throwback = [
  {
    id: 1,
    name: "Né Barrako 27",
    cover: "images/throwback/ne.png",
    desc: "",
  },

  {
    id: 2,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas.png",
    desc: "",
  },

  {
    id: 3,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas2.png",
    desc: "",
  },

  {
    id: 4,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas3.png",
    desc: "",
  },

  {
    id: 5,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas4.png",
    desc: "",
  },

  {
    id: 6,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas5.png",
    desc: "",
  },

  {
    id: 7,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas6.png",
    desc: "",
  },

  {
    id: 8,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas7.png",
    desc: "",
  },

  {
    id: 9,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas8.png",
    desc: "",
  },

  {
    id: 10,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/eventoMar.png",
    desc: "",
  },

  {
    id: 11,
    name: "Artistas que passaram pela Oldschool",
    cover: "images/throwback/artistas9.png",
    desc: "",
  },

  {
    id: 12,
    name: "Apoiadores da Oldschool",
    cover: "images/throwback/apoiadores.png",
    desc: "",
  },

  {
    id: 13,
    name: "Apoiadores da Oldschool",
    cover: "images/throwback/associacaopais.png",
    desc: "",
  },

  {
    id: 14,
    name: "Fornecedores Montana",
    cover: "images/throwback/fornecedores.png",
    desc: "",
  },
];

export const galleries = [
  {
    id: 1,
    name: "Arte",
    path: "/arte",
    img: "images/gallery/photo-svgrepo-com.svg",
  },

  {
    id: 2,
    name: "Serviços",
    path: "/servicos",
    img: "images/gallery/mall-svgrepo-com.svg",
  },

  {
    id: 3,
    name: "Projetos",
    path: "/projetos",
    img: "images/gallery/camera-svgrepo-com.svg",
  },

  {
    id: 4,
    name: "Participações",
    path: "/participacoes",
    img: "images/gallery/set-up-svgrepo-com.svg",
  },
];
