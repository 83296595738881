export const prompts = [
  {
    id: 1,
    question: "Horário de atendimento",
    answer:
      "Olá Writer! Estamos abertos de segunda a quinta-feira das 14h00 às 20h00, e sexta-feira e sábado das 12h00 às 20h00. Cá te esperamos!",
  },

  {
    id: 2,
    question: "Contato",
    answer:
      "Contata-nos através do número +351 917 274 301, ou diretamente através das nossas redes sociais!",
  },

  {
    id: 3,
    question: "Encomendas personalizadas",
    answer:
      "Se precisas de artigos que não estão disponíveis na loja, ou de grandes volumes de artigos, entra em contato connosco para arranjarmos uma solução para ti!",
  },

  {
    id: 4,
    question: "Colaborações",
    answer:
      "Tens talento? És curioso? 👀 Queres colaborar connosco e não sabes como? Entra em contato connosco e vamos conversar!🤙",
  },

  {
    id: 5,
    question: "Artes",
    answer:
      "És artista e tens alguma obra que gostarias de ver exposta ou vendida na nossa loja? Fala connosco!🖌️",
  },

  {
    id: 6,
    question: "Como falar connosco",
    answer:
      "Fala connosco através do Instagram, Whatsapp, ou telemóvel! No rodapé do nosso site tens os links!",
  },
];
