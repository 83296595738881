import React from 'react';

function NotFound() {
    return (
        <div className="notFound">
            <h1>404 - Página não encontrada</h1>
            <p>A página que está a procurar não existe.</p>
        </div>
    );
}

export default NotFound;
