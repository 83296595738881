export const colors = [
  {
    colorId: 1,
    brandID: 1,
    code: "RV-189",
    name: "Ipanema Yellow",
    cmyk: "0, 0, 18, 1",
    hex: "#fff9c3",
    pantone: "607 U",
  },

  {
    colorId: 2,
    brandID: 1,
    code: "RV-20",
    name: "Party Yellow",
    cmyk: "0, 2, 69, 0",
    hex: "#fff058",
    pantone: "106 U",
  },

  {
    colorId: 3,
    brandID: 1,
    code: "RV-109",
    name: "Canarias Yellow",
    cmyk: "0, 1, 100, 0",
    hex: "#fedf00",
    pantone: "108 U",
  },

  {
    colorId: 4,
    brandID: 1,
    code: "RV-110",
    name: "Yosemite Yellow",
    cmyk: "0, 2, 100, 20",
    hex: "#c7b300",
    pantone: "612 U",
  },

  {
    colorId: 5,
    brandID: 1,
    code: "RV-111",
    name: "Babel Green",
    cmyk: "0,3,10,41",
    hex: "#a29400",
    pantone: "3985 U",
  },

  {
    colorId: 6,
    brandID: 1,
    code: "RV-112",
    name: "Mission Green",
    cmyk: "0, 12, 100, 49",
    hex: "#766a10",
    pantone: "119 U",
  },

  {
    colorId: 7,
    brandID: 1,
    code: "RV-113",
    name: "Dragon Green",
    cmyk: "0,3,10,64",
    hex: "#5e5003",
    pantone: "3995 U",
  },

  {
    colorId: 8,
    brandID: 1,
    code: "RV-114",
    name: "Maya Green",
    cmyk: "0,0,71,94",
    hex: "#3d3408",
    pantone: "5815 U",
  },

  {
    colorId: 9,
    brandID: 1,
    code: "RV-192",
    name: "Sundance",
    cmyk: "0,10,94,0",
    hex: "#ffebc2",
    pantone: "7405 U",
  },

  {
    colorId: 10,
    brandID: 1,
    code: "RV-102",
    name: "Dalai Orange",
    cmyk: "0,10,30,0",
    hex: "#fddca3",
    pantone: "7507 U",
  },

  {
    colorId: 11,
    brandID: 1,
    code: "RV-103",
    name: "Plural Orange",
    cmyk: "0,23,47,0",
    hex: "#fac67e",
    pantone: "149 U",
  },

  {
    colorId: 12,
    brandID: 1,
    code: "RV-50",
    name: "Solar Orange",
    cmyk: "0,35,69,0",
    hex: "#f7b060",
    pantone: "7411 U",
  },

  {
    colorId: 13,
    brandID: 1,
    code: "RV-105",
    name: "Tangerine",
    cmyk: "0,35,70,0",
    hex: "#f5a54a",
    pantone: "150 U",
  },

  {
    colorId: 14,
    brandID: 1,
    code: "RV-106",
    name: "Lava Orange",
    cmyk: "0, 48, 100, 0",
    hex: "#ef8407",
    pantone: "144 U",
  },

  {
    colorId: 15,
    brandID: 1,
    code: "RV-2004",
    name: "Kalani Orange",
    cmyk: "0,49,66,0",
    hex: "#ea640d",
    pantone: "1645 U",
  },

  {
    colorId: 16,
    brandID: 1,
    code: "RV-107",
    name: "Mars Orange",
    cmyk: "0, 68, 100, 0",
    hex: "#e64715",
    pantone: "1665 U",
  },

  {
    colorId: 17,
    brandID: 1,
    code: "RV-108",
    name: "Phoenix Orange",
    cmyk: "0, 75, 100, 28",
    hex: "#be3819",
    pantone: "1675 U",
  },

  {
    colorId: 18,
    brandID: 1,
    code: "RV-190",
    name: "Malta White",
    cmyk: "0,12,28,0",
    hex: "#f9f6e9",
    pantone: "155 U",
  },

  {
    colorId: 19,
    brandID: 1,
    code: "RV-1013",
    name: "Bone White",
    cmyk: "0,2,15,0",
    hex: "#f3efd6",
    pantone: "7499 U",
  },

  {
    colorId: 20,
    brandID: 1,
    code: "RV-135",
    name: "Safari Brown",
    cmyk: "3,3,35,0",
    hex: "#eadfa8",
    pantone: "461 U",
  },

  {
    colorId: 21,
    brandID: 1,
    code: "RV-136",
    name: "Inca Brown",
    cmyk: "0,20,50,5",
    hex: "#d9b365",
    pantone: "7509 U",
  },

  {
    colorId: 22,
    brandID: 1,
    code: "RV-137",
    name: "Kraft Brown",
    cmyk: "20, 32, 58, 0",
    hex: "#d9b365",
    pantone: "465 U",
  },

  {
    colorId: 23,
    brandID: 1,
    code: "RV-139",
    name: "Sequoia Brown",
    cmyk: "30, 56, 10, 37",
    hex: "#d9b365",
    pantone: "463 U",
  },

  {
    colorId: 24,
    brandID: 1,
    code: "RV-138",
    name: "Marrakech",
    cmyk: "30, 30, 60,10",
    hex: "#99743f",
    pantone: "873 U",
  },

  {
    colorId: 25,
    brandID: 1,
    code: "RV-140",
    name: "Mole Brown",
    cmyk: "0, 26, 100, 69",
    hex: "#503915",
    pantone: "4485 U",
  },

  {
    colorId: 26,
    brandID: 1,
    code: "RV-141",
    name: "Gondola Brown",
    cmyk: "0, 60, 100, 79",
    hex: "#412c13",
    pantone: "4625 U",
  },

  {
    colorId: 27,
    brandID: 1,
    code: "RV-191",
    name: "Jaima Brown",
    cmyk: "0,8,23,2",
    hex: "#f1e8de",
    pantone: "726 U",
  },

  {
    colorId: 28,
    brandID: 1,
    code: "RV-94",
    name: "Dingo Brown",
    cmyk: "0,8,23,2",
    hex: "#e8d0b7",
    pantone: "726 U",
  },

  {
    colorId: 29,
    brandID: 1,
    code: "RV-95",
    name: "Tana Brown",
    cmyk: "0, 21, 48, 10",
    hex: "#d2a785",
    pantone: "728 U",
  },

  {
    colorId: 30,
    brandID: 1,
    code: "RV-96",
    name: "Montserrat",
    cmyk: "0, 31, 62, 18",
    hex: "#cc955e",
    pantone: "729 U",
  },

  {
    colorId: 31,
    brandID: 1,
    code: "RV-97",
    name: "Chiapas Brown",
    cmyk: "0, 38, 78, 29",
    hex: "#a0682b",
    pantone: "730 U",
  },

  {
    colorId: 32,
    brandID: 1,
    code: "RV-98",
    name: "Bean Brown",
    cmyk: "0,52,100,35",
    hex: "#a65f2f",
    pantone: "7516 U",
  },

  {
    colorId: 33,
    brandID: 1,
    code: "RV-100",
    name: "Coffee Brown",
    cmyk: "0, 60, 100, 79",
    hex: "#402009",
    pantone: "4625 U",
  },

  {
    colorId: 34,
    brandID: 1,
    code: "RV-35",
    name: "Chocolate Brown",
    cmyk: "75, 80, 80, 45",
    hex: "#41302a",
    pantone: "412 U",
  },

  {
    colorId: 35,
    brandID: 1,
    code: "RV-101",
    name: "Ebony Brown",
    cmyk: "0, 73, 100, 80",
    hex: "#2f1109",
    pantone: "4975 U",
  },

  {
    colorId: 36,
    brandID: 1,
    code: "RV-193",
    name: "Chewing Gum",
    cmyk: "0,28,7,0",
    hex: "#f6c3d3",
    pantone: "1895 U",
  },

  {
    colorId: 37,
    brandID: 1,
    code: "RV-115",
    name: "Tutti Frutti",
    cmyk: "0, 45, 10, 0",
    hex: "#ea949d",
    pantone: "197 U",
  },

  {
    colorId: 38,
    brandID: 1,
    code: "RV-3017",
    name: "Fever Red",
    cmyk: "0, 59, 56, 0",
    hex: "#dc534d",
    pantone: "178 U",
  },

  {
    colorId: 39,
    brandID: 1,
    code: "RV-116",
    name: "Blood Red",
    cmyk: "0, 79, 100, 0",
    hex: "#d52f25",
    pantone: "179 U",
  },

  {
    colorId: 40,
    brandID: 1,
    code: "RV-3001",
    name: "Vivid Red",
    cmyk: "0, 100, 99, 4",
    hex: "#b3061e",
    pantone: "1797 U",
  },

  {
    colorId: 41,
    brandID: 1,
    code: "RV-47",
    name: "Clandestine Red",
    cmyk: "0, 100, 65, 28",
    hex: "#950825",
    pantone: "7427 U",
  },

  {
    colorId: 42,
    brandID: 1,
    code: "RV-3004",
    name: "Bordeaux Red",
    cmyk: "0, 90, 100, 51",
    hex: "#610819",
    pantone: "1815 U",
  },

  {
    colorId: 43,
    brandID: 1,
    code: "RV-3007",
    name: "Cherokee Red",
    cmyk: "0, 70, 100, 78",
    hex: "#3a0807",
    pantone: "497 U",
  },

  {
    colorId: 44,
    brandID: 1,
    code: "RV-77",
    name: "Night Red",
    cmyk: "0, 73, 100, 80",
    hex: "#2d0604",
    pantone: "4975 U",
  },

  {
    colorId: 45,
    brandID: 1,
    code: "RV-194",
    name: "Supernova Pink",
    cmyk: "0,9,6,0",
    hex: "#fce6eb",
    pantone: "705 U",
  },

  {
    colorId: 46,
    brandID: 1,
    code: "RV-164",
    name: "Tokyo Pink",
    cmyk: "0,17,11,0",
    hex: "#f7c7d7",
    pantone: "706 U",
  },

  {
    colorId: 47,
    brandID: 1,
    code: "RV-165",
    name: "Orchid Pink",
    cmyk: "0,58,3,0",
    hex: "#ec7faa",
    pantone: "204 U",
  },

  {
    colorId: 48,
    brandID: 1,
    code: "RV-4003",
    name: "Erika",
    cmyk: "0,72,11,0",
    hex: "#e85b99",
    pantone: "212 U",
  },

  {
    colorId: 49,
    brandID: 1,
    code: "RV-4010",
    name: "Magenta",
    cmyk: "0,99,0,0",
    hex: "#d90075",
    pantone: "226 U",
  },

  {
    colorId: 50,
    brandID: 1,
    code: "RV-166",
    name: "Açai Red",
    cmyk: "0, 100, 15, 30",
    hex: "#9a004b",
    pantone: "221 U",
  },

  {
    colorId: 51,
    brandID: 1,
    code: "RV-167",
    name: "Rioja Red",
    cmyk: "40, 100, 0, 28",
    hex: "#6b0037",
    pantone: "249 U",
  },

  {
    colorId: 52,
    brandID: 1,
    code: "RV-168",
    name: "Anger Red",
    cmyk: "0, 80, 45, 55",
    hex: "#550026",
    pantone: "7428 U",
  },

  {
    colorId: 53,
    brandID: 1,
    code: "RV-169",
    name: "Taurus Red",
    cmyk: "0, 100, 30, 61",
    hex: "#36001c",
    pantone: "7421 U",
  },

  {
    colorId: 54,
    brandID: 1,
    code: "RV-196",
    name: "Saudade Pink",
    cmyk: "0,16,8,0",
    hex: "#f2dddd",
    pantone: "698 U",
  },

  {
    colorId: 55,
    brandID: 1,
    code: "RV-86",
    name: "Boreal Pink",
    cmyk: "0,18,10,1",
    hex: "#e3bfc3",
    pantone: "502 U",
  },

  {
    colorId: 56,
    brandID: 1,
    code: "RV-87",
    name: "Stereo Pink",
    cmyk: "0, 36, 21, 10",
    hex: "#ca95a4",
    pantone: "694 U",
  },

  {
    colorId: 57,
    brandID: 1,
    code: "RV-88",
    name: "Single Pink",
    cmyk: "5, 35, 15, 25",
    hex: "#a06c7e",
    pantone: "8062 U",
  },

  {
    colorId: 58,
    brandID: 1,
    code: "RV-89",
    name: "Compact Red",
    cmyk: "0, 59, 48, 48",
    hex: "#864a54",
    pantone: "4985 U",
  },

  {
    colorId: 59,
    brandID: 1,
    code: "RV-90",
    name: "Bitacora Red",
    cmyk: "50, 100, 100, 25",
    hex: "#582b3a",
    pantone: "505 U",
  },

  {
    colorId: 60,
    brandID: 1,
    code: "RV-91",
    name: "Pandora Red",
    cmyk: "65, 100, 100, 35",
    hex: "#4f1f30",
    pantone: "504 U",
  },

  {
    colorId: 61,
    brandID: 1,
    code: "RV-92",
    name: "Gaudi Red",
    cmyk: "32, 42, 0, 55",
    hex: "#321129",
    pantone: "7448 U",
  },

  {
    colorId: 62,
    brandID: 1,
    code: "RV-93",
    name: "Stendhal Red",
    cmyk: "72, 100, 77, 40",
    hex: "#1e000e",
    pantone: "7449 U",
  },

  {
    colorId: 63,
    brandID: 1,
    code: "RV-197",
    name: "Penelope Brown",
    cmyk: "5, 10 , 3, 0",
    hex: "#f8ede7",
    pantone: "4685 U",
  },

  {
    colorId: 64,
    brandID: 1,
    code: "RV-176",
    name: "Sensible Brown",
    cmyk: "0,11,8,0",
    hex: "#efd9d0",
    pantone: "503 U",
  },

  {
    colorId: 65,
    brandID: 1,
    code: "RV-203",
    name: "Oak Brown",
    cmyk: "0, 40, 35, 20",
    hex: "#a86059",
    pantone: "7523 U",
  },

  {
    colorId: 66,
    brandID: 1,
    code: "RV-204",
    name: "Interrail Brown",
    cmyk: "0, 70, 66, 30",
    hex: "#9f3d44",
    pantone: "492 U",
  },

  {
    colorId: 67,
    brandID: 1,
    code: "RV-205",
    name: "Warrior Brown",
    cmyk: "0, 100, 30, 61",
    hex: "#5a1521",
    pantone: "7421 U",
  },

  {
    colorId: 68,
    brandID: 1,
    code: "RV-200",
    name: "Plancton",
    cmyk: "6,0,23,0",
    hex: "#e8d6c8",
    pantone: "4681 U",
  },

  {
    colorId: 69,
    brandID: 1,
    code: "RV-64",
    name: "Respect Pink",
    cmyk: "0,8,21,32",
    hex: "#b59892",
    pantone: "7530 U",
  },

  {
    colorId: 70,
    brandID: 1,
    code: "RV-201",
    name: "Scarlet Brown",
    cmyk: "0, 48, 38, 34",
    hex: "#8e6061",
    pantone: "4995 U",
  },

  {
    colorId: 71,
    brandID: 1,
    code: "RV-202",
    name: "Mosquito Brown",
    cmyk: "0, 48, 38, 34",
    hex: "#684445",
    pantone: "7518 U",
  },

  {
    colorId: 72,
    brandID: 1,
    code: "RV-321",
    name: "April Violet",
    cmyk: "8, 25, 0, 0",
    hex: "#e9cde4",
    pantone: "263 U",
  },

  {
    colorId: 73,
    brandID: 1,
    code: "RV-195",
    name: "Shiva Violet",
    cmyk: "7,20,0,0",
    hex: "#e5cce1",
    pantone: "256 U",
  },

  {
    colorId: 74,
    brandID: 1,
    code: "RV-322",
    name: "Arlet Violet",
    cmyk: "20, 38, 0, 0",
    hex: "#d0add2",
    pantone: "257 U",
  },

  {
    colorId: 75,
    brandID: 1,
    code: "RV-170",
    name: "Persia Violet",
    cmyk: "17,37,0,0",
    hex: "#c5add0",
    pantone: "522 U",
  },

  {
    colorId: 76,
    brandID: 1,
    code: "RV-323",
    name: "Mandala Violet",
    cmyk: "30, 50, 0, 0",
    hex: "#bc90c1",
    pantone: "2573 U",
  },

  {
    colorId: 77,
    brandID: 1,
    code: "RV-276",
    name: "Bishop Violet",
    cmyk: "40,65,0,0",
    hex: "#C29BCB",
    pantone: "257 U",
  },

  {
    colorId: 78,
    brandID: 1,
    code: "RV-275",
    name: "Raval Violet",
    cmyk: "39,69,3,1",
    hex: "#94679C",
    pantone: "259 U",
  },

  {
    colorId: 79,
    brandID: 1,
    code: "RV-283",
    name: "Sultan Violet",
    cmyk: "51, 83, 3, 20",
    hex: "#83577D",
    pantone: "255 U",
  },

  {
    colorId: 80,
    brandID: 1,
    code: "RV-274",
    name: "Reverend Violet",
    cmyk: "58, 85, 29, 46",
    hex: "#6C5D80",
    pantone: "2695 U",
  },

  {
    colorId: 81,
    brandID: 1,
    code: "RV-310",
    name: "Republic Violet",
    cmyk: "16, 23, 0, 0",
    hex: "#dacbe5",
    pantone: "665 U",
  },

  {
    colorId: 82,
    brandID: 1,
    code: "RV-311",
    name: "Woodstock Violet",
    cmyk: "22, 25, 0, 0",
    hex: "#cdc3e1",
    pantone: "2635 U",
  },

  {
    colorId: 83,
    brandID: 1,
    code: "RV-171",
    name: "Community Violet",
    cmyk: "20,35,0,0",
    hex: "#b194c0",
    pantone: "7439 U",
  },

  {
    colorId: 84,
    brandID: 1,
    code: "RV-172",
    name: "Destiny Violet",
    cmyk: "20,35,0,0",
    hex: "#8270a3",
    pantone: "667 U",
  },

  {
    colorId: 85,
    brandID: 1,
    code: "RV-173",
    name: "Ultraviolet",
    cmyk: "52, 49, 0, 14",
    hex: "#6b4d8d",
    pantone: "7447 U",
  },

  {
    colorId: 86,
    brandID: 1,
    code: "RV-174",
    name: "Venus Violet",
    cmyk: "60, 58, 0, 19",
    hex: "#483366",
    pantone: "669 U",
  },

  {
    colorId: 87,
    brandID: 1,
    code: "RV-175",
    name: "Electra Violet",
    cmyk: "76, 78, 0, 47",
    hex: "#32234e",
    pantone: "7479 U",
  },

  {
    colorId: 88,
    brandID: 1,
    code: "RV-28",
    name: "Cosmos Violet",
    cmyk: "55,0,50,0",
    hex: "#241c46",
    pantone: "2765 U",
  },

  {
    colorId: 89,
    brandID: 1,
    code: "RV-27",
    name: "Vampire Violet",
    cmyk: "100, 97, 0, 45",
    hex: "#140632",
    pantone: "276 U",
  },

  {
    colorId: 90,
    brandID: 1,
    code: "RV-312",
    name: "Sagan Blue",
    cmyk: "28, 18, 0, 0",
    hex: "#c1cae8",
    pantone: "2706 U",
  },

  {
    colorId: 91,
    brandID: 1,
    code: "RV-313",
    name: "Martínez Blue",
    cmyk: "36, 21, 0, 0",
    hex: "#aebee3",
    pantone: "2716 U",
  },

  {
    colorId: 92,
    brandID: 1,
    code: "RV-314",
    name: "Rosemary Blue",
    cmyk: "43, 26, 0, 0",
    hex: "#9db1dc",
    pantone: "7451 U",
  },

  {
    colorId: 93,
    brandID: 1,
    code: "RV-315",
    name: "Dancer Blue",
    cmyk: "53, 32, 0, 0",
    hex: "#85a0d3",
    pantone: "7452 U",
  },

  {
    colorId: 94,
    brandID: 1,
    code: "RV-316",
    name: "Marseille Blue",
    cmyk: "66, 43, 0, 0",
    hex: "#6386c3",
    pantone: "2718 U",
  },

  {
    colorId: 95,
    brandID: 1,
    code: "RV-317",
    name: "Porto Blue",
    cmyk: "70, 58, 0, 0",
    hex: "#616cb1",
    pantone: "2726 U",
  },

  {
    colorId: 96,
    brandID: 1,
    code: "RV-318",
    name: "Ween Blue",
    cmyk: "86, 69, 0, 0",
    hex: "#3b54a1",
    pantone: "7455 U",
  },

  {
    colorId: 97,
    brandID: 1,
    code: "RV-319",
    name: "Tuareg Blue",
    cmyk: "100, 90, 2, 5",
    hex: "#263284",
    pantone: "2748 U",
  },

  {
    colorId: 98,
    brandID: 1,
    code: "RV-320",
    name: "Mantra Blue",
    cmyk: "100, 100, 35, 0",
    hex: "#302b66",
    pantone: "2766 U",
  },

  {
    colorId: 99,
    brandID: 1,
    code: "RV-184",
    name: "Rain Blue",
    cmyk: "100, 100, 0, 58",
    hex: "#c4e7f8",
    pantone: "290 U",
  },

  {
    colorId: 100,
    brandID: 1,
    code: "RV-149",
    name: "Hydra Blue",
    cmyk: "69, 7, 0, 0",
    hex: "#8ecae5",
    pantone: "298 U",
  },

  {
    colorId: 101,
    brandID: 1,
    code: "RV-150",
    name: "Argo Blue",
    cmyk: "41, 2, 0, 0",
    hex: "#44b3df",
    pantone: "2905 U",
  },

  {
    colorId: 102,
    brandID: 1,
    code: "RV-151",
    name: "Freedom Blue",
    cmyk: "85, 19, 0, 0",
    hex: "#0098d2",
    pantone: "299 U",
  },

  {
    colorId: 103,
    brandID: 1,
    code: "RV-152",
    name: "Europe Blue",
    cmyk: "85, 24, 0, 0",
    hex: "#0074b0",
    pantone: "2925 U",
  },

  {
    colorId: 104,
    brandID: 1,
    code: "RV-30",
    name: "Electric Blue",
    cmyk: "100, 30, 0, 20",
    hex: "#005891",
    pantone: "3005 U",
  },

  {
    colorId: 105,
    brandID: 1,
    code: "RV-5005",
    name: "Dark Blue",
    cmyk: "100, 58, 0, 21",
    hex: "#003875",
    pantone: "294 U",
  },

  {
    colorId: 106,
    brandID: 1,
    code: "RV-154",
    name: "Twister Blue",
    cmyk: "100, 68, 0, 52",
    hex: "#042151",
    pantone: "655 U",
  },

  {
    colorId: 107,
    brandID: 1,
    code: "RV-5013",
    name: "Navy Blue",
    cmyk: "100, 68, 0, 54",
    hex: "#010e30",
    pantone: "282 U",
  },

  {
    colorId: 108,
    brandID: 1,
    code: "RV-185",
    name: "Angel Blue",
    cmyk: "100, 68, 0, 54",
    hex: "#eaf0f5",
    pantone: "282 U",
  },

  {
    colorId: 109,
    brandID: 1,
    code: "RV-156",
    name: "Barceloneta Blue",
    cmyk: "12,0,2,0",
    hex: "#cae4f0",
    pantone: "7457 U",
  },

  {
    colorId: 110,
    brandID: 1,
    code: "RV-157",
    name: "Thalassa Blue",
    cmyk: "33,3,0,0",
    hex: "#a2d3ea",
    pantone: "291 U",
  },

  {
    colorId: 111,
    brandID: 1,
    code: "RV-158",
    name: "Perseus Blue",
    cmyk: "40,0,5,6",
    hex: "#7fb8d5",
    pantone: "7458 U",
  },

  {
    colorId: 112,
    brandID: 1,
    code: "RV-159",
    name: "Odyssey Blue",
    cmyk: "52,6,0,25",
    hex: "#6ea4be",
    pantone: "549 U",
  },

  {
    colorId: 113,
    brandID: 1,
    code: "RV-160",
    name: "Eureka Blue",
    cmyk: "58, 17, 0, 46",
    hex: "#467289",
    pantone: "5405 U",
  },

  {
    colorId: 114,
    brandID: 1,
    code: "RV-161",
    name: "Jase Blue",
    cmyk: "33,4,0,72",
    hex: "#2e4a5b",
    pantone: "7546 U",
  },

  {
    colorId: 115,
    brandID: 1,
    code: "RV-162",
    name: "Deep Blue",
    cmyk: "100, 11, 0, 74",
    hex: "#003447",
    pantone: "303 U",
  },

  {
    colorId: 116,
    brandID: 1,
    code: "RV-163",
    name: "Poseydon Blue",
    cmyk: "100, 19, 0, 75",
    hex: "#00202c",
    pantone: "547 U",
  },

  {
    colorId: 117,
    brandID: 1,
    code: "RV-186",
    name: "Virgin Green",
    cmyk: "6,0, 4,11",
    hex: "#f5faf7",
    pantone: "5527 U",
  },

  {
    colorId: 118,
    brandID: 1,
    code: "RV-142",
    name: "Gemini Green",
    cmyk: "13, 0 , 10, 2",
    hex: "#e1f0e8",
    pantone: "621 U",
  },

  {
    colorId: 119,
    brandID: 1,
    code: "RV-6027",
    name: "Luminous Green",
    cmyk: "25,0,10,0",
    hex: "#a1d5cf",
    pantone: "7464 U",
  },

  {
    colorId: 120,
    brandID: 1,
    code: "RV-144",
    name: "Bali Green",
    cmyk: "48,0,29,0",
    hex: "#7bc6c0",
    pantone: "570 U",
  },

  {
    colorId: 121,
    brandID: 1,
    code: "RV-145",
    name: "Erasmus Green",
    cmyk: "52,0,25,0",
    hex: "#5bb5b5",
    pantone: "7472 U",
  },

  {
    colorId: 122,
    brandID: 1,
    code: "RV-5018",
    name: "Turquoise",
    cmyk: "90, 0, 28, 22",
    hex: "#008691",
    pantone: "7474 U",
  },

  {
    colorId: 123,
    brandID: 1,
    code: "RV-146",
    name: "Glory Blue",
    cmyk: "82, 0, 28, 52",
    hex: "#2d6a75",
    pantone: "5473 U",
  },

  {
    colorId: 124,
    brandID: 1,
    code: "RV-147",
    name: "Pegasus Blue",
    cmyk: "100, 24, 0, 64",
    hex: "#003640",
    pantone: "548 U",
  },

  {
    colorId: 125,
    brandID: 1,
    code: "RV-148",
    name: "Hercules Blue",
    cmyk: "100, 0, 18, 83",
    hex: "#002628",
    pantone: "5463 U",
  },

  {
    colorId: 126,
    brandID: 1,
    code: "RV-187",
    name: "Vespa Green",
    cmyk: "8, 0, 16, 0",
    hex: "#f1f5df",
    pantone: "7485 U",
  },

  {
    colorId: 127,
    brandID: 1,
    code: "RV-124",
    name: "Frisco Green",
    cmyk: "12,0,29,0",
    hex: "#d4e19e",
    pantone: "585 U",
  },

  {
    colorId: 128,
    brandID: 1,
    code: "RV-16",
    name: "Pistachio Green",
    cmyk: "11,0, 66,2",
    hex: "#bdd268",
    pantone: "377 U",
  },

  {
    colorId: 129,
    brandID: 1,
    code: "RV-125",
    name: "Neon Green",
    cmyk: "45, 0 ,100, 24",
    hex: "#a5b700",
    pantone: "377 U",
  },

  {
    colorId: 130,
    brandID: 1,
    code: "RV-34",
    name: "Guacamole Green",
    cmyk: "23, 0, 100, 17",
    hex: "#81a512",
    pantone: "583 U",
  },

  {
    colorId: 131,
    brandID: 1,
    code: "RV-6018",
    name: "Valley Green",
    cmyk: "12,0,29,0",
    hex: "#4a7518",
    pantone: "365 U",
  },

  {
    colorId: 132,
    brandID: 1,
    code: "RV-126",
    name: "Toscana Green",
    cmyk: "34, 0, 81, 71",
    hex: "#2b491e",
    pantone: "574 U",
  },

  {
    colorId: 133,
    brandID: 1,
    code: "RV-127",
    name: "Era Green",
    cmyk: "65, 0, 56, 94",
    hex: "#142f0f",
    pantone: "5605 U",
  },

  {
    colorId: 134,
    brandID: 1,
    code: "RV-6009",
    name: "Amazon Green",
    cmyk: "66, 0, 57, 82",
    hex: "#0f2405",
    pantone: "5535 U",
  },

  {
    colorId: 135,
    brandID: 1,
    code: "RV-188",
    name: "Shining Green",
    cmyk: "0, 0, 18, 1",
    hex: "#f2edbe",
    pantone: "607 U",
  },

  {
    colorId: 136,
    brandID: 1,
    code: "RV-1016",
    name: "Lemon Yellow",
    cmyk: "11, 0, 66, 2",
    hex: "#dcd872",
    pantone: "585 U",
  },

  {
    colorId: 137,
    brandID: 1,
    code: "RV-129",
    name: "Oregano Green",
    cmyk: "23, 0, 100, 17",
    hex: "#afab00",
    pantone: "583 U",
  },

  {
    colorId: 138,
    brandID: 1,
    code: "RV-60",
    name: "Krypton Green",
    cmyk: "13, 0, 100, 46",
    hex: "#83890e",
    pantone: "582 U",
  },

  {
    colorId: 139,
    brandID: 1,
    code: "RV-130",
    name: "Euskadi Green",
    cmyk: "34, 0, 100, 60",
    hex: "#50590d",
    pantone: "378 U",
  },

  {
    colorId: 140,
    brandID: 1,
    code: "RV-131",
    name: "Comarca Green",
    cmyk: "32, 0, 100, 79",
    hex: "#333b08",
    pantone: "5747 U",
  },

  {
    colorId: 141,
    brandID: 1,
    code: "RV-132",
    name: "Borneo Green",
    cmyk: "33, 0, 85, 82",
    hex: "#283307",
    pantone: "5743 U",
  },

  {
    colorId: 142,
    brandID: 1,
    code: "RV-133",
    name: "Dharma Green",
    cmyk: "66, 0, 57, 82",
    hex: "#1e2705",
    pantone: "5535 U",
  },

  {
    colorId: 143,
    brandID: 1,
    code: "RV-134",
    name: "Infinite Green",
    cmyk: "65, 0, 56, 94",
    hex: "#131c04",
    pantone: "5605 U",
  },

  {
    colorId: 144,
    brandID: 1,
    code: "RV-270",
    name: "Formentera Blue",
    cmyk: "65,0,20,0",
    hex: "#00B4BC",
    pantone: "319 U",
  },

  {
    colorId: 145,
    brandID: 1,
    code: "RV-269",
    name: "Genesis Blue",
    cmyk: "75, 5, 15, 10",
    hex: "#179DAB",
    pantone: "7711 U",
  },

  {
    colorId: 146,
    brandID: 1,
    code: "RV-268",
    name: "Tramontana Blue",
    cmyk: "100, 0, 10, 35",
    hex: "#007788",
    pantone: "3145 U",
  },

  {
    colorId: 147,
    brandID: 1,
    code: "RV-199",
    name: "Java Green",
    cmyk: "31,0,20,0",
    hex: "#86c8b4",
    pantone: "337 U",
  },

  {
    colorId: 148,
    brandID: 1,
    code: "RV-182",
    name: "Emerald Green",
    cmyk: "70,0,38,8",
    hex: "#009e8f",
    pantone: "7473 U",
  },

  {
    colorId: 149,
    brandID: 1,
    code: "RV-183",
    name: "Beryl Green",
    cmyk: "85, 0, 50, 31",
    hex: "#117774",
    pantone: "562 U",
  },

  {
    colorId: 150,
    brandID: 1,
    code: "RV-179",
    name: "Bonsai Green",
    cmyk: "7,0,31,13",
    hex: "#b8bb86",
    pantone: "5787 U",
  },

  {
    colorId: 151,
    brandID: 1,
    code: "RV-180",
    name: "Thai Green",
    cmyk: "9,0,43,38",
    hex: "#8e966e",
    pantone: "5773 U",
  },

  {
    colorId: 152,
    brandID: 1,
    code: "RV-181",
    name: "Labyrinth Green",
    cmyk: "3,0,31,75",
    hex: "#47492b",
    pantone: "418 U",
  },

  {
    colorId: 153,
    brandID: 1,
    code: "RV-267",
    name: "Sulfur Yellow",
    cmyk: "5,1,93,0",
    hex: "#FAEB37",
    pantone: "3955 U",
  },

  {
    colorId: 154,
    brandID: 1,
    code: "RV-266",
    name: "Psycho Green",
    cmyk: "19,0,91,0",
    hex: "#D5E739",
    pantone: "380 U",
  },

  {
    colorId: 155,
    brandID: 1,
    code: "RV-265",
    name: "Sonar Green",
    cmyk: "29,0,99,9",
    hex: "#B9BE56",
    pantone: "7744 U",
  },

  {
    colorId: 156,
    brandID: 1,
    code: "RV-281",
    name: "Laser Green",
    cmyk: "50,0,85,0",
    hex: "#99C221",
    pantone: "382 U",
  },

  {
    colorId: 157,
    brandID: 1,
    code: "RV-282",
    name: "Laos Green",
    cmyk: "50, 0, 100, 0",
    hex: "#8FCB24",
    pantone: "2293 U",
  },

  {
    colorId: 158,
    brandID: 1,
    code: "RV-280",
    name: "Hulk Green",
    cmyk: "80, 0, 100, 0",
    hex: "#41A248",
    pantone: "2272 U",
  },

  {
    colorId: 159,
    brandID: 1,
    code: "RV-272",
    name: "Mint Green",
    cmyk: "49, 0, 60, 0",
    hex: "#29CB7E",
    pantone: "7479 U",
  },

  {
    colorId: 160,
    brandID: 1,
    code: "RV-273",
    name: "Iguana Green",
    cmyk: "47,0,85,0",
    hex: "#8CC947",
    pantone: "2292 U",
  },

  {
    colorId: 161,
    brandID: 1,
    code: "RV-271",
    name: "Mystic Green",
    cmyk: "77,0,90,0",
    hex: "#25BD59",
    pantone: "2271 U",
  },

  {
    colorId: 162,
    brandID: 1,
    code: "RV-1017",
    name: "Peach",
    cmyk: "0, 30, 60,, 2",
    hex: "#E6A65D",
    pantone: "7411 U",
  },

  {
    colorId: 163,
    brandID: 1,
    code: "RV-1028",
    name: "Medium Yellow",
    cmyk: "0,35,70,0",
    hex: "#FFA300",
    pantone: "137 U",
  },

  {
    colorId: 164,
    brandID: 1,
    code: "RV-207",
    name: "Mango",
    cmyk: "0,50,70,0",
    hex: "#E87722",
    pantone: "158 U",
  },

  {
    colorId: 165,
    brandID: 1,
    code: "RV-177",
    name: "Eldorado",
    cmyk: "0,10,99,0",
    hex: "#f1c700",
    pantone: "7405 U",
  },

  {
    colorId: 166,
    brandID: 1,
    code: "RV-178",
    name: "Tibet",
    cmyk: "0, 28, 100, 18",
    hex: "#d58b26",
    pantone: "1245 U",
  },

  {
    colorId: 167,
    brandID: 1,
    code: "RV-8023",
    name: "Mustard",
    cmyk: "0, 46, 100, 34",
    hex: "#a76225",
    pantone: "154 U",
  },

  {
    colorId: 168,
    brandID: 1,
    code: "RV-99",
    name: "Glace Brown",
    cmyk: "0, 57, 100, 59",
    hex: "#7a3d24",
    pantone: "168 U",
  },

  {
    colorId: 169,
    brandID: 1,
    code: "RV-278",
    name: "Joker Pink",
    cmyk: "5,65,3,0",
    hex: "#E44c9A",
    pantone: "Rhod. Red U",
  },

  {
    colorId: 170,
    brandID: 1,
    code: "RV-277",
    name: "Disco Pink",
    cmyk: "24,80,0,0",
    hex: "#BD51A2",
    pantone: "247 U",
  },

  {
    colorId: 171,
    brandID: 1,
    code: "RV-279",
    name: "Rosario Pink",
    cmyk: "2,95,4,0",
    hex: "#DC4588",
    pantone: "226 U",
  },

  {
    colorId: 172,
    brandID: 1,
    code: "RV-3020",
    name: "Light Red",
    cmyk: "0,90,85,0",
    hex: "#ef3a38",
    pantone: "032 U",
  },

  {
    colorId: 173,
    brandID: 1,
    code: "RV-241",
    name: "Madrid Red",
    cmyk: "1, 10, 78, 10",
    hex: "#F65058",
    pantone: "Red 032",
  },

  {
    colorId: 174,
    brandID: 1,
    code: "RV-245",
    name: "Cyan",
    cmyk: "74,3,27,0",
    hex: "#00FFFF",
    pantone: "312 U",
  },

  {
    colorId: 175,
    brandID: 1,
    code: "RV-243",
    name: "Babylon Blue",
    cmyk: "100, 65, 18, 17",
    hex: "#3D5588",
    pantone: "288 U",
  },

  {
    colorId: 176,
    brandID: 1,
    code: "RV-198",
    name: "Stardust Grey",
    cmyk: "0, 0, 0, 6",
    hex: "#eef0f1",
    pantone: "Cool Grey 1 U",
  },

  {
    colorId: 177,
    brandID: 1,
    code: "RV-7047",
    name: "Siberian Grey",
    cmyk: "0,0,0,10",
    hex: "#dbdddf",
    pantone: "Cool Grey 2 U",
  },

  {
    colorId: 178,
    brandID: 1,
    code: "RV-118",
    name: "Rita Grey",
    cmyk: "0,0,0,11",
    hex: "#cdd0d1",
    pantone: "427 U",
  },

  {
    colorId: 179,
    brandID: 1,
    code: "RV-7040",
    name: "Pearl Grey",
    cmyk: "0,0,0,29",
    hex: "#cdd0d1",
    pantone: "Cool Grey 5 U",
  },

  {
    colorId: 180,
    brandID: 1,
    code: "RV-119",
    name: "London Grey",
    cmyk: "0,0,0,40",
    hex: "#898b8e",
    pantone: "877 U",
  },

  {
    colorId: 181,
    brandID: 1,
    code: "RV-120",
    name: "Wolf Grey",
    cmyk: "0,0,0,77",
    hex: "#5b5b5e",
    pantone: "425 U",
  },

  {
    colorId: 182,
    brandID: 1,
    code: "RV-121",
    name: "Icarus Grey",
    cmyk: "0,0,0,37",
    hex: "#48484b",
    pantone: "Cool Grey 11 U",
  },

  {
    colorId: 183,
    brandID: 1,
    code: "RV-7016",
    name: "Anthracite Grey",
    cmyk: "21, 0, 23, 75",
    hex: "#363538",
    pantone: "446 U",
  },

  {
    colorId: 184,
    brandID: 1,
    code: "RV-78",
    name: "Metropolis Grey",
    cmyk: "16, 0, 31, 82",
    hex: "#262227",
    pantone: "447 U",
  },

  {
    colorId: 185,
    brandID: 1,
    code: "RV-300",
    name: "Tofu Grey",
    cmyk: "1, 1, 15, 0",
    hex: "#fef9e3",
    pantone: "Cool Grey 1",
  },

  {
    colorId: 186,
    brandID: 1,
    code: "RV-301",
    name: "Placebo Grey",
    cmyk: "5, 5, 20, 0",
    hex: "#fef9e3",
    pantone: "Warm Grey 1",
  },

  {
    colorId: 187,
    brandID: 1,
    code: "RV-302",
    name: "Koala Grey",
    cmyk: "18, 19, 23, 0",
    hex: "#d8cdc3",
    pantone: "406 U",
  },

  {
    colorId: 188,
    brandID: 1,
    code: "RV-303",
    name: "Native Grey",
    cmyk: "31, 29, 30, 0",
    hex: "#bcb2ad",
    pantone: "Warm Grey 4",
  },

  {
    colorId: 189,
    brandID: 1,
    code: "RV-304",
    name: "Balboa Grey",
    cmyk: "43, 40, 41, 0",
    hex: "#a29690",
    pantone: "408 U",
  },

  {
    colorId: 190,
    brandID: 1,
    code: "RV-305",
    name: "Cloud Grey",
    cmyk: "25, 13, 14, 0",
    hex: "#c9d2d7",
    pantone: "537 U",
  },

  {
    colorId: 191,
    brandID: 1,
    code: "RV-306",
    name: "Winter Grey",
    cmyk: "30, 17, 13, 5",
    hex: "#b7c1cd",
    pantone: "651 U",
  },

  {
    colorId: 192,
    brandID: 1,
    code: "RV-307",
    name: "Jaws Grey",
    cmyk: "31, 18, 13, 12",
    hex: "#abb5c1",
    pantone: "536 U",
  },

  {
    colorId: 193,
    brandID: 1,
    code: "RV-308",
    name: "Whale Grey",
    cmyk: "66, 48, 35, 0",
    hex: "#6b7c91",
    pantone: "5415 U",
  },

  {
    colorId: 194,
    brandID: 1,
    code: "RV-309",
    name: "Chernobyl Grey",
    cmyk: "70, 50, 38, 5",
    hex: "#5e7386",
    pantone: "5405 U",
  },

  {
    colorId: 195,
    brandID: 1,
    code: "RV-9010",
    name: "White",
    cmyk: "0, 0, 0, 0",
    hex: "#FFFFFF",
    pantone: "",
  },

  {
    colorId: 196,
    brandID: 1,
    code: "RV-9011",
    name: "Black",
    cmyk: "0, 0, 0, 100",
    hex: "#000000",
    pantone: "",
  },

  {
    colorId: 197,
    brandID: 1,
    code: "",
    name: "Jewel Silver",
    cmyk: "",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 198,
    brandID: 1,
    code: "",
    name: "Frame Gold",
    cmyk: "",
    hex: "#D4AF37",
    pantone: "",
  },

  {
    colorId: 199,
    brandID: 1,
    code: "",
    name: "Copper Triumph",
    cmyk: "",
    hex: "#B87333",
    pantone: "",
  },

  {
    colorId: 200,
    brandID: 1,
    code: "",
    name: "Fluorescent Yellow",
    cmyk: "",
    hex: "#fdef4c",
    pantone: "",
  },

  {
    colorId: 201,
    brandID: 1,
    code: "",
    name: "Fluerescent Orange",
    cmyk: "",
    hex: "#f68b1f",
    pantone: "",
  },

  {
    colorId: 202,
    brandID: 1,
    code: "",
    name: "Fluorescent Red",
    cmyk: "",
    hex: "#ef3a4d",
    pantone: "",
  },

  {
    colorId: 203,
    brandID: 1,
    code: "",
    name: "Fluorescent Fucsia",
    cmyk: "",
    hex: "#ee4d9b",
    pantone: "",
  },

  {
    colorId: 204,
    brandID: 1,
    code: "",
    name: "Fluorescent Violet",
    cmyk: "",
    hex: "#9857a5",
    pantone: "",
  },

  {
    colorId: 205,
    brandID: 1,
    code: "",
    name: "Fluorescent Blue",
    cmyk: "",
    hex: "#008dd2",
    pantone: "",
  },

  {
    colorId: 206,
    brandID: 1,
    code: "",
    name: "Fluorescent Green",
    cmyk: "",
    hex: "#61bb46",
    pantone: "",
  },

  {
    colorId: 207,
    brandID: 1,
    code: "",
    name: "Ethereal Yellow Spectral",
    cmyk: "",
    hex: "#fffaa3",
    pantone: "",
  },

  {
    colorId: 208,
    brandID: 1,
    code: "",
    name: "Calima Orange Spectral",
    cmyk: "",
    hex: "#fbb36d",
    pantone: "",
  },

  {
    colorId: 209,
    brandID: 1,
    code: "",
    name: "Soul Red Spectral",
    cmyk: "",
    hex: "#eda795",
    pantone: "",
  },

  {
    colorId: 210,
    brandID: 1,
    code: "",
    name: "Judea Brown Spectral",
    cmyk: "",
    hex: "#b79e74",
    pantone: "",
  },

  {
    colorId: 211,
    brandID: 1,
    code: "",
    name: "Aura Violet Spectral",
    cmyk: "",
    hex: "#c5add0",
    pantone: "",
  },

  {
    colorId: 212,
    brandID: 1,
    code: "",
    name: "Atmosphere Blue Spectral",
    cmyk: "",
    hex: "#afccdf",
    pantone: "",
  },

  {
    colorId: 213,
    brandID: 1,
    code: "",
    name: "Breeze Green Spectral",
    cmyk: "",
    hex: "#a1d5cf",
    pantone: "",
  },

  {
    colorId: 214,
    brandID: 1,
    code: "",
    name: "Air White Spectral",
    cmyk: "",
    hex: "#ffffff",
    pantone: "",
  },

  {
    colorId: 215,
    brandID: 1,
    code: "",
    name: "Spectral Smoke Grey",
    cmyk: "",
    hex: "#cdcdcd",
    pantone: "",
  },

  {
    colorId: 216,
    brandID: 1,
    code: "",
    name: "Shadow Black Spectral",
    cmyk: "",
    hex: "#a6a6a7",
    pantone: "",
  },

  {
    colorId: 217,
    brandID: 2,
    code: "RV-252",
    name: "Unicorn Yellow",
    cmyk: "1, 2, 24, 0",
    hex: "#FFFFBF",
    pantone: "7499 C",
  },

  {
    colorId: 218,
    brandID: 2,
    code: "RV-222",
    name: "Beach Yellow",
    cmyk: "1, 0, 58, 0",
    hex: "#FFF386",
    pantone: "393 C",
  },

  {
    colorId: 219,
    brandID: 2,
    code: "RV-7",
    name: "Cream",
    cmyk: "0,18,50,0",
    hex: "#fff7b6",
    pantone: "1345 C",
  },

  {
    colorId: 220,
    brandID: 2,
    code: "RV-20",
    name: "Party Yellow",
    cmyk: "0, 0, 79, O",
    hex: "#FFF048",
    pantone: "101 C",
  },

  {
    colorId: 221,
    brandID: 2,
    code: "RV-1021",
    name: "Light Yellow",
    cmyk: "0, 15, 100, 0",
    hex: "#FFE100",
    pantone: "108 C",
  },

  {
    colorId: 222,
    brandID: 2,
    code: "RV-239",
    name: "Luxor Yellow",
    cmyk: "0, 32, 100, 0",
    hex: "#FAB700",
    pantone: "130 C",
  },

  {
    colorId: 223,
    brandID: 2,
    code: "RV-11",
    name: "Ganges Yellow",
    cmyk: "6, 20, 88, 0",
    hex: "#C8A10A",
    pantone: "1235 C",
  },

  {
    colorId: 224,
    brandID: 2,
    code: "RV-206",
    name: "Atacama Yellow",
    cmyk: "0,25,50,0",
    hex: "#FBCB8C",
    pantone: "149 C",
  },

  {
    colorId: 225,
    brandID: 2,
    code: "RV-1017",
    name: "Peach",
    cmyk: "0,42,75,2",
    hex: "#F9BA57",
    pantone: "7411 C",
  },

  {
    colorId: 226,
    brandID: 2,
    code: "RV-1028",
    name: "Medium Yellow",
    cmyk: "0, 41, 100, 0",
    hex: "#F6A800",
    pantone: "138 C",
  },

  {
    colorId: 227,
    brandID: 2,
    code: "RV-207",
    name: "Mango",
    cmyk: "0, 62, 95, 0",
    hex: "#E77A19",
    pantone: "158 C",
  },

  {
    colorId: 228,
    brandID: 2,
    code: "RV-8023",
    name: "Mustard",
    cmyk: "0, 72, 95, 28",
    hex: "#E77A19",
    pantone: "174 C",
  },

  {
    colorId: 229,
    brandID: 2,
    code: "RV-261",
    name: "Pangea Brown",
    cmyk: "5, 83, 100, 27",
    hex: "#933B00",
    pantone: "174 C",
  },

  {
    colorId: 230,
    brandID: 2,
    code: "RV-18",
    name: "Rust Red",
    cmyk: "13,83,100,62",
    hex: "#6D2601",
    pantone: "168 C",
  },

  {
    colorId: 231,
    brandID: 2,
    code: "RV-9",
    name: "Apricot",
    cmyk: "0,30,45,0",
    hex: "#FAC294",
    pantone: "1555 C",
  },

  {
    colorId: 232,
    brandID: 2,
    code: "RV-208",
    name: "Pumpkin",
    cmyk: "0, 40, 61, 0",
    hex: "#F6AD6B",
    pantone: "7411 C",
  },

  {
    colorId: 233,
    brandID: 2,
    code: "RV-2003",
    name: "Pastel Orange",
    cmyk: "0, 40, 61, 0",
    hex: "#EC753B",
    pantone: "172 C",
  },

  {
    colorId: 234,
    brandID: 2,
    code: "RV-209",
    name: "Calcutta Orange",
    cmyk: "0, 85, 90, 6",
    hex: "#DC4126",
    pantone: "1788 C",
  },

  {
    colorId: 235,
    brandID: 2,
    code: "RV-210",
    name: "Prometheus Orange",
    cmyk: "0, 100, 100, 47",
    hex: "#910e14",
    pantone: "188 C",
  },

  {
    colorId: 236,
    brandID: 2,
    code: "RV-260",
    name: "Iroko Red",
    cmyk: "29, 82, 50, 73",
    hex: "#910e14",
    pantone: "7361 C",
  },

  {
    colorId: 237,
    brandID: 2,
    code: "RV-259",
    name: "Flamingo",
    cmyk: "0,55,50,0",
    hex: "#FF958E",
    pantone: "486 C",
  },

  {
    colorId: 238,
    brandID: 2,
    code: "RV-33",
    name: "Colorado Red",
    cmyk: "0,81,69,0",
    hex: "#E74F45",
    pantone: "710 C",
  },

  {
    colorId: 239,
    brandID: 2,
    code: "RV-3020",
    name: "Light Red",
    cmyk: "0,90,85,0",
    hex: "#E5352D",
    pantone: "032 C",
  },

  {
    colorId: 240,
    brandID: 2,
    code: "RV-3001",
    name: "Vivid Red",
    cmyk: "0, 100, 100, 15",
    hex: "#C90019",
    pantone: "187 C",
  },

  {
    colorId: 241,
    brandID: 2,
    code: "RV-241",
    name: "Madrid Red",
    cmyk: "0, 78, 73,0",
    hex: "#C52332",
    pantone: "032 C",
  },

  {
    colorId: 241,
    brandID: 2,
    code: "RV-242",
    name: "Soviet Red",
    cmyk: "0, 83,100, 47",
    hex: "#8d0000",
    pantone: "491 C",
  },

  {
    colorId: 242,
    brandID: 2,
    code: "RV-3004",
    name: "Bordeaux Red",
    cmyk: "27, 100, 93, 40",
    hex: "#82131D",
    pantone: "188 C",
  },

  {
    colorId: 243,
    brandID: 2,
    code: "RV-348",
    name: "Alice Pink",
    cmyk: "0,43,7,0",
    hex: "#F4ADC5",
    pantone: "509 C",
  },

  {
    colorId: 244,
    brandID: 2,
    code: "RV-258",
    name: "Manga Pink",
    cmyk: "0,40,8,0",
    hex: "#FFA2CA",
    pantone: "700 C",
  },

  {
    colorId: 245,
    brandID: 2,
    code: "RV-211",
    name: "Love Pink",
    cmyk: "0,60,4,0",
    hex: "#EE87AD",
    pantone: "218 C",
  },

  {
    colorId: 246,
    brandID: 2,
    code: "RV-4003",
    name: "Erika",
    cmyk: "5,90,31,0",
    hex: "#DE336B",
    pantone: "7425 C",
  },

  {
    colorId: 247,
    brandID: 2,
    code: "RV-4010",
    name: "Magenta",
    cmyk: "5,95,43,0",
    hex: "#DD1D5A",
    pantone: "213 C",
  },

  {
    colorId: 248,
    brandID: 2,
    code: "RV-212",
    name: "Akari Red",
    cmyk: "10, 100, 60, 10",
    hex: "#C50041",
    pantone: "1945 C",
  },

  {
    colorId: 249,
    brandID: 2,
    code: "RV-213",
    name: "Merlot Red",
    cmyk: "16, 82, 39, 56",
    hex: "#762A3D",
    pantone: "7428 C",
  },

  {
    colorId: 250,
    brandID: 2,
    code: "RV-349",
    name: "Violet Treze",
    cmyk: "0,38,4,0",
    hex: "#F6B8D0",
    pantone: "210 C",
  },

  {
    colorId: 251,
    brandID: 2,
    code: "RV-257",
    name: "Princess Violet",
    cmyk: "4,51,7,6",
    hex: "#CA87B6",
    pantone: "507 C",
  },

  {
    colorId: 252,
    brandID: 2,
    code: "RV-224",
    name: "Witch Violet",
    cmyk: "22, 58,10, 0",
    hex: "#C8739F",
    pantone: "688 C",
  },

  {
    colorId: 253,
    brandID: 2,
    code: "RV-225",
    name: "Geisha Violet",
    cmyk: "25,75,9,0",
    hex: "#C15C92",
    pantone: "2385 C",
  },

  {
    colorId: 254,
    brandID: 2,
    code: "RV-350",
    name: "Pure Purple",
    cmyk: "13,96,22,43",
    hex: "#8F154B",
    pantone: "215 C",
  },

  {
    colorId: 255,
    brandID: 2,
    code: "RV-226",
    name: "Tube Violet",
    cmyk: "30, 72, 16, 44",
    hex: "#7C3F60",
    pantone: "5125 C",
  },

  {
    colorId: 256,
    brandID: 2,
    code: "RV-4007",
    name: "Cherry",
    cmyk: "30, 74, 16, 68",
    hex: "#582741",
    pantone: "5115 C",
  },

  {
    colorId: 257,
    brandID: 2,
    code: "RV-256",
    name: "Malva",
    cmyk: "29,25,0,0",
    hex: "#ACA6D1",
    pantone: "270 C",
  },

  {
    colorId: 258,
    brandID: 2,
    code: "RV-214",
    name: "Violet",
    cmyk: "46, 44, 0, 0",
    hex: "#9993C3",
    pantone: "2577 C",
  },

  {
    colorId: 259,
    brandID: 2,
    code: "RV-215",
    name: "Prophet Violet",
    cmyk: "66,65,0,0",
    hex: "#6D63A5",
    pantone: "7447 C",
  },

  {
    colorId: 260,
    brandID: 2,
    code: "RV-3",
    name: "Blue Violet",
    cmyk: "77,78,0,0",
    hex: "#574A96",
    pantone: "668 C",
  },

  {
    colorId: 261,
    brandID: 2,
    code: "RV-216",
    name: "Anonymous Violet",
    cmyk: "80, 85, 0, 21",
    hex: "#45347A",
    pantone: "5265 C",
  },

  {
    colorId: 262,
    brandID: 2,
    code: "RV-264",
    name: "Galaxy Violet",
    cmyk: "100, 100, 10, 79",
    hex: "#2F2280",
    pantone: "276 C",
  },

  {
    colorId: 263,
    brandID: 2,
    code: "RV-27",
    name: "Vampire Violet",
    cmyk: "",
    hex: "#00084D",
    pantone: "",
  },

  {
    colorId: 264,
    brandID: 2,
    code: "RV-73",
    name: "Aurora Blue",
    cmyk: "25,14,0,0",
    hex: "#C8D3ED",
    pantone: "7450 C",
  },

  {
    colorId: 265,
    brandID: 2,
    code: "RV-351",
    name: "Hortensia Blue",
    cmyk: "52,34,0,0",
    hex: "#889ed1",
    pantone: "7452 C",
  },

  {
    colorId: 266,
    brandID: 2,
    code: "RV-352",
    name: "Gala Blue",
    cmyk: "70,51,0,0",
    hex: "#5d78ba",
    pantone: "2727 C",
  },

  {
    colorId: 267,
    brandID: 2,
    code: "RV-353",
    name: "Julione Blue",
    cmyk: "85,70,2,0",
    hex: "#40549f",
    pantone: "2726 C",
  },

  {
    colorId: 268,
    brandID: 2,
    code: "RV-243",
    name: "Babylon Blue",
    cmyk: "100, 65, 18, 17",
    hex: "#3D5588",
    pantone: "Violet C",
  },

  {
    colorId: 269,
    brandID: 2,
    code: "RV-32",
    name: "Lewis Blue",
    cmyk: "100,85,0,0",
    hex: "#213a8f",
    pantone: "7687 C",
  },

  {
    colorId: 270,
    brandID: 2,
    code: "RV-354",
    name: "Universe Blue",
    cmyk: "100,80,4,55",
    hex: "#0F2354",
    pantone: "2766 C",
  },

  {
    colorId: 271,
    brandID: 2,
    code: "RV-74",
    name: "Costa Brava Blue",
    cmyk: "46,15,2,0",
    hex: "#94BFE5",
    pantone: "542 C",
  },

  {
    colorId: 272,
    brandID: 2,
    code: "RV-227",
    name: "Waimea Blue",
    cmyk: "60, 30, 0, 0",
    hex: "#6F9FD0",
    pantone: "645 C",
  },

  {
    colorId: 273,
    brandID: 2,
    code: "RV-228",
    name: "Zeppelin Blue",
    cmyk: "75,50,0,2",
    hex: "#4875B3",
    pantone: "7455 C",
  },

  {
    colorId: 274,
    brandID: 2,
    code: "RV-229",
    name: "Andromeda Blue",
    cmyk: "80,70,0,5",
    hex: "#465499",
    pantone: "2726 C",
  },

  {
    colorId: 275,
    brandID: 2,
    code: "RV-5002",
    name: "Ultramarine Blue",
    cmyk: "",
    hex: "#37306A",
    pantone: "669 C",
  },

  {
    colorId: 276,
    brandID: 2,
    code: "RV-230",
    name: "Leviathan Blue",
    cmyk: "100, 100, 0, 60",
    hex: "#0D0F49",
    pantone: "276 C",
  },

  {
    colorId: 277,
    brandID: 2,
    code: "RV-75",
    name: "Nostromo Blue",
    cmyk: "100, 71, 39, 90",
    hex: "#020E37",
    pantone: "5395 C",
  },

  {
    colorId: 278,
    brandID: 2,
    code: "RV-13",
    name: "Himalayan Blue",
    cmyk: "50,0,12,0",
    hex: "#97e6ff",
    pantone: "305 C",
  },

  {
    colorId: 279,
    brandID: 2,
    code: "RV-29",
    name: "Arctic Blue",
    cmyk: "60,0,3,0",
    hex: "#6BDCFF",
    pantone: "2985 C",
  },

  {
    colorId: 280,
    brandID: 2,
    code: "RV-8",
    name: "Carlota Light Blue",
    cmyk: "60,0,4,0",
    hex: "#60C5E7",
    pantone: "2985 C",
  },

  {
    colorId: 281,
    brandID: 2,
    code: "RV-217",
    name: "Avatar Blue",
    cmyk: "90,5,1,0",
    hex: "#00A2DD",
    pantone: "312 C",
  },

  {
    colorId: 282,
    brandID: 2,
    code: "RV-30",
    name: "Electric Blue",
    cmyk: "100, 32, 0, 23",
    hex: "#007FC4",
    pantone: "3005 C",
  },

  {
    colorId: 283,
    brandID: 2,
    code: "RV-5005",
    name: "Dark Blue",
    cmyk: "100, 30, 0, 30",
    hex: "#00659D",
    pantone: "301 C",
  },

  {
    colorId: 284,
    brandID: 2,
    code: "RV-218",
    name: "Neptune Blue",
    cmyk: "100, 50, 0, 45",
    hex: "#004578",
    pantone: "295 C",
  },

  {
    colorId: 285,
    brandID: 2,
    code: "RV-355",
    name: "Fukushima Blue",
    cmyk: "48,0,20,0",
    hex: "#8ECFD3",
    pantone: "3105 C",
  },

  {
    colorId: 286,
    brandID: 2,
    code: "RV-255",
    name: "Patagonia Blue",
    cmyk: "",
    hex: "#83CCDF",
    pantone: "2985 C",
  },

  {
    colorId: 287,
    brandID: 2,
    code: "RV-231",
    name: "Cousteau Blue",
    cmyk: "73,0,2,22",
    hex: "#1BB2C4",
    pantone: "3125 C",
  },

  {
    colorId: 288,
    brandID: 2,
    code: "RV-232",
    name: "Glacier Blue",
    cmyk: "90,0,31,6",
    hex: "#009CAB",
    pantone: "326 C",
  },

  {
    colorId: 289,
    brandID: 2,
    code: "RV-14",
    name: "Planet Blue",
    cmyk: "100, 0, 27, 24",
    hex: "#008098",
    pantone: "321 C",
  },

  {
    colorId: 290,
    brandID: 2,
    code: "RV-233",
    name: "Christiania Blue",
    cmyk: "100, 30, 40, 26",
    hex: "#006675",
    pantone: "5473 C",
  },

  {
    colorId: 291,
    brandID: 2,
    code: "RV-234",
    name: "Indigo Blue",
    cmyk: "100, 36, 45, 53",
    hex: "#004750",
    pantone: "316 C",
  },

  {
    colorId: 292,
    brandID: 2,
    code: "RV-356",
    name: "Vintage Blue",
    cmyk: "45,0,22,0",
    hex: "#97D2D0",
    pantone: "571 C",
  },

  {
    colorId: 293,
    brandID: 2,
    code: "RV-357",
    name: "Iceberg Blue",
    cmyk: "58,0,30,0",
    hex: "#6EC3BF",
    pantone: "570 C",
  },

  {
    colorId: 294,
    brandID: 2,
    code: "RV-358",
    name: "Water Lily Blue",
    cmyk: "60,0,33,10",
    hex: "#60B3AC",
    pantone: "2401 C",
  },

  {
    colorId: 295,
    brandID: 2,
    code: "RV-359",
    name: "Mururoa Blue",
    cmyk: "85,5,40,20",
    hex: "#008C8D",
    pantone: "7716 C",
  },

  {
    colorId: 296,
    brandID: 2,
    code: "RV-5021",
    name: "Caribbean Blue",
    cmyk: "84,8,40,29",
    hex: "#007F80",
    pantone: "7474 C",
  },

  {
    colorId: 297,
    brandID: 2,
    code: "RV-360",
    name: "Major Blue",
    cmyk: "75,18,40,50",
    hex: "#1F6363",
    pantone: "7722 C",
  },

  {
    colorId: 298,
    brandID: 2,
    code: "RV-5020",
    name: "Ocean Blue",
    cmyk: "89,22,34,66",
    hex: "#00454E",
    pantone: "7426 C",
  },

  {
    colorId: 299,
    brandID: 2,
    code: "RV-361",
    name: "Potosi Green",
    cmyk: "40,0,28,0",
    hex: "#C5EFDA",
    pantone: "332 C",
  },

  {
    colorId: 300,
    brandID: 2,
    code: "RV-254",
    name: "Max Green",
    cmyk: "27,0,15,0",
    hex: "#9FE5C2",
    pantone: "331 C",
  },

  {
    colorId: 301,
    brandID: 2,
    code: "RV-219",
    name: "Paris Green",
    cmyk: "55,0,40,0",
    hex: "#8BC8AA",
    pantone: "563 C",
  },

  {
    colorId: 302,
    brandID: 2,
    code: "RV-220",
    name: "UFO Green",
    cmyk: "65,0,45,0",
    hex: "#5BB89E",
    pantone: "3395 C",
  },

  {
    colorId: 303,
    brandID: 2,
    code: "RV-21",
    name: "Surgical Green",
    cmyk: "100, 0, 66, 0",
    hex: "#009473",
    pantone: "334 C",
  },

  {
    colorId: 304,
    brandID: 2,
    code: "RV-6016",
    name: "Dark Green",
    cmyk: "100, 0, 80, 15",
    hex: "#008352",
    pantone: "3415 C",
  },

  {
    colorId: 305,
    brandID: 2,
    code: "RV-221",
    name: "Persephone Green",
    cmyk: "73, 0, 82, 59",
    hex: "#005231",
    pantone: "343 C",
  },

  {
    colorId: 306,
    brandID: 2,
    code: "RV-24",
    name: "Golden Green",
    cmyk: "45,0,50,0",
    hex: "#9CCD9A",
    pantone: "2269 C",
  },

  {
    colorId: 307,
    brandID: 2,
    code: "RV-4",
    name: "Light Green",
    cmyk: "60,0,81,0",
    hex: "#74B957",
    pantone: "360 C",
  },

  {
    colorId: 308,
    brandID: 2,
    code: "RV-34",
    name: "Guacamole Green",
    cmyk: "56,0,78,0",
    hex: "#88B14F",
    pantone: "360 C",
  },

  {
    colorId: 309,
    brandID: 2,
    code: "RV-6018",
    name: "Valley Green",
    cmyk: "61, 10, 100, 15",
    hex: "#88B14F",
    pantone: "370 C",
  },

  {
    colorId: 310,
    brandID: 2,
    code: "RV-237",
    name: "Cologno N. Green",
    cmyk: "50, 5, 10, 38",
    hex: "#6A8515",
    pantone: "576 C",
  },

  {
    colorId: 311,
    brandID: 2,
    code: "RV-5",
    name: "Lutecia Green",
    cmyk: "90, 12, 95, 40",
    hex: "#005900",
    pantone: "349 C",
  },

  {
    colorId: 312,
    brandID: 2,
    code: "RV-6009",
    name: "Amazonas Green",
    cmyk: "66, 0, 57, 82",
    hex: "#0F2405",
    pantone: "5535 C",
  },

  {
    colorId: 313,
    brandID: 2,
    code: "RV-235",
    name: "Poison Green",
    cmyk: "20,0,85,O",
    hex: "#DEDD3A",
    pantone: "389 C",
  },

  {
    colorId: 314,
    brandID: 2,
    code: "RV-235",
    name: "Poison Green",
    cmyk: "20,0,85,O",
    hex: "#DEDD3A",
    pantone: "389 C",
  },

  {
    colorId: 315,
    brandID: 2,
    code: "RV-236",
    name: "Mojito Green",
    cmyk: "25,0,95,0",
    hex: "#CED50A",
    pantone: "382 C",
  },

  {
    colorId: 316,
    brandID: 2,
    code: "RV-16",
    name: "Pistachio Green",
    cmyk: "40,4,85,0",
    hex: "#B0C643",
    pantone: "375 C",
  },

  {
    colorId: 317,
    brandID: 2,
    code: "RV-362",
    name: "Mantis Green",
    cmyk: "58,0,65,0",
    hex: "#78BE7A",
    pantone: "353 C",
  },

  {
    colorId: 318,
    brandID: 2,
    code: "RV-272",
    name: "Mint Green",
    cmyk: "65,0,75,0",
    hex: "#60B565",
    pantone: "360 C",
  },

  {
    colorId: 319,
    brandID: 2,
    code: "RV-363",
    name: "Natura Green",
    cmyk: "100,0,90,0",
    hex: "#00964F",
    pantone: "334 C",
  },

  {
    colorId: 320,
    brandID: 2,
    code: "RV-364",
    name: "Reggae Green",
    cmyk: "98,6,80,40",
    hex: "#006942",
    pantone: "3425 C",
  },

  {
    colorId: 321,
    brandID: 2,
    code: "RV-6019",
    name: "Pale Green",
    cmyk: "26,0,42,0",
    hex: "#CCE0AC",
    pantone: "358 C",
  },

  {
    colorId: 322,
    brandID: 2,
    code: "RV-253",
    name: "Element Green",
    cmyk: "25,4,44,3",
    hex: "#C5CC8F",
    pantone: "7493 C",
  },

  {
    colorId: 323,
    brandID: 2,
    code: "RV-15",
    name: "Apple Green",
    cmyk: "20, 0, 47, 28",
    hex: "#ACB681",
    pantone: "5773 C",
  },

  {
    colorId: 324,
    brandID: 2,
    code: "RV-250",
    name: "Rambo Green",
    cmyk: "23, 0, 60, 42",
    hex: "#8F995B",
    pantone: "5767 C",
  },

  {
    colorId: 325,
    brandID: 2,
    code: "RV-250",
    name: "Rambo Green",
    cmyk: "23, 0, 60, 42",
    hex: "#8F995B",
    pantone: "5767 C",
  },

  {
    colorId: 326,
    brandID: 2,
    code: "RV-6013",
    name: "Khaki Green",
    cmyk: "28, 0, 64, 55",
    hex: "#727E48",
    pantone: "5763 C",
  },

  {
    colorId: 327,
    brandID: 2,
    code: "RV-6003",
    name: "Olive Green",
    cmyk: "28, 0, 64, 79",
    hex: "#60683D",
    pantone: "438 C",
  },

  {
    colorId: 328,
    brandID: 2,
    code: "RV-251",
    name: "Forest Green",
    cmyk: "24, 0, 61, 68",
    hex: "#46502C",
    pantone: "5763 C",
  },

  {
    colorId: 329,
    brandID: 2,
    code: "RV-246",
    name: "Druide Brown",
    cmyk: "8,20,45,0",
    hex: "#EECF99",
    pantone: "466 C",
  },

  {
    colorId: 330,
    brandID: 2,
    code: "RV-247",
    name: "Tepuy Brown",
    cmyk: "15, 33, 58, 0",
    hex: "#DEB276",
    pantone: "465 C",
  },

  {
    colorId: 331,
    brandID: 2,
    code: "RV-248",
    name: "Baobab Brown",
    cmyk: "23, 76, 41, 0",
    hex: "#CC9456",
    pantone: "4645 C",
  },

  {
    colorId: 332,
    brandID: 2,
    code: "RV-249",
    name: "Greyound Brown",
    cmyk: "37, 58, 82, 0",
    hex: "#B0763F",
    pantone: "7524 C",
  },

  {
    colorId: 333,
    brandID: 2,
    code: "RV-8002",
    name: "Toasted Brown",
    cmyk: "0, 52, 70, 55",
    hex: "#8A5531",
    pantone: "4705 C",
  },

  {
    colorId: 334,
    brandID: 2,
    code: "RV-8017",
    name: "Tobacco Brown",
    cmyk: "0, 60, 100, 79",
    hex: "#402009",
    pantone: "4625 C",
  },

  {
    colorId: 335,
    brandID: 2,
    code: "RV-35",
    name: "Chocolate Brown",
    cmyk: "37, 53, 68, 83",
    hex: "#4D2200",
    pantone: "7533 C",
  },

  {
    colorId: 336,
    brandID: 2,
    code: "RV-365",
    name: "Puchineli White",
    cmyk: "2,2,20,0",
    hex: "#F9F7EA",
    pantone: "7401 C",
  },

  {
    colorId: 337,
    brandID: 2,
    code: "RV-1013",
    name: "Bone White",
    cmyk: "0,2,15,0",
    hex: "#F3EFD6",
    pantone: "7499 C",
  },

  {
    colorId: 338,
    brandID: 2,
    code: "RV-55",
    name: "Arkalis Grey",
    cmyk: "3,4,14,8",
    hex: "#EBE6D6",
    pantone: "7527 C",
  },

  {
    colorId: 339,
    brandID: 2,
    code: "RV-57",
    name: "City Grey",
    cmyk: "7,14,20,21",
    hex: "#CABDB0",
    pantone: "7529 C",
  },

  {
    colorId: 340,
    brandID: 2,
    code: "RV-42",
    name: "Regina Grey",
    cmyk: "12,20,30,27",
    hex: "#B7A794",
    pantone: "7530 C",
  },

  {
    colorId: 341,
    brandID: 2,
    code: "RV-40",
    name: "Nazgul Grey",
    cmyk: "16,23,23,44",
    hex: "#928782",
    pantone: "",
  },

  {
    colorId: 342,
    brandID: 2,
    code: "RV-39",
    name: "Ripley Grey",
    cmyk: "30,23,20,45",
    hex: "#7E7F82",
    pantone: "424 C",
  },

  {
    colorId: 343,
    brandID: 2,
    code: "RV-6",
    name: "Light Grey",
    cmyk: "14, 9, 9, 23",
    hex: "#e1e2e2",
    pantone: "",
  },

  {
    colorId: 344,
    brandID: 2,
    code: "RV-366",
    name: "Squatter Grey",
    cmyk: "11,8,8,21",
    hex: "#C4C5C6",
    pantone: "",
  },

  {
    colorId: 345,
    brandID: 2,
    code: "RV-262",
    name: "Matter Grey",
    cmyk: "14, 9, 9, 23",
    hex: "#7b7b7b",
    pantone: "429 C",
  },

  {
    colorId: 346,
    brandID: 2,
    code: "RV-7040",
    name: "Pearl Grey",
    cmyk: "0, 0, 0,65",
    hex: "#7B7C7E",
    pantone: "431 C",
  },

  {
    colorId: 347,
    brandID: 2,
    code: "RV-7031",
    name: "Dark Grey",
    cmyk: "0, 0, 0, 76",
    hex: "#616264",
    pantone: "425 C",
  },

  {
    colorId: 348,
    brandID: 2,
    code: "RV-263",
    name: "Sputnik Grey",
    cmyk: "41, 28, 22, 70",
    hex: "#575757",
    pantone: "7540 C",
  },

  {
    colorId: 349,
    brandID: 2,
    code: "RV-7016",
    name: "Anthracite Grey",
    cmyk: "0, 0, 0, 87",
    hex: "#464648",
    pantone: "447 C",
  },

  {
    colorId: 350,
    brandID: 2,
    code: "RV-9010",
    name: "Divinity White",
    cmyk: "0, 0, 0, 0",
    hex: "#FFFFFF",
    pantone: "",
  },

  {
    colorId: 351,
    brandID: 2,
    code: "RV-9011",
    name: "Gloss Black",
    cmyk: "0, 0, 0, 100",
    hex: "#000000",
    pantone: "",
  },

  {
    colorId: 352,
    brandID: 2,
    code: "Silver Chrome",
    name: "Silver Chrome",
    cmyk: "",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 353,
    brandID: 2,
    code: "Metallic Gold",
    name: "Metallic Gold",
    cmyk: "",
    hex: "#D4AF37",
    pantone: "",
  },

  {
    colorId: 354,
    brandID: 2,
    code: "Metallic Copper",
    name: "Metallic Copper",
    cmyk: "",
    hex: "#B87333",
    pantone: "",
  },

  {
    colorId: 355,
    brandID: 3,
    code: "RV-1021",
    name: "Light Yellow",
    cmyk: "0, 8, 95, 0",
    hex: "#ffe705",
    pantone: "108 C",
  },

  {
    colorId: 356,
    brandID: 3,
    code: "RV-2003",
    name: "Pastel Orange",
    cmyk: "0,65,60,0",
    hex: "#eb690d",
    pantone: "172 C",
  },

  {
    colorId: 357,
    brandID: 3,
    code: "RV-3020",
    name: "Light Red",
    cmyk: "0,90,85,0",
    hex: "#c90119",
    pantone: "032 C",
  },

  {
    colorId: 358,
    brandID: 3,
    code: "RV-3004",
    name: "Bordeaux Red",
    cmyk: "27, 100, 93, 40",
    hex: "#82131D",
    pantone: "188 C",
  },

  {
    colorId: 359,
    brandID: 3,
    code: "RV-211",
    name: "Love Pink",
    cmyk: "0,60,4,0",
    hex: "#EE87AD",
    pantone: "218 C",
  },

  {
    colorId: 360,
    brandID: 3,
    code: "RV-4010",
    name: "Magenta",
    cmyk: "5,95,43,0",
    hex: "#e2007a",
    pantone: "213 C",
  },

  {
    colorId: 361,
    brandID: 3,
    code: "RV-225",
    name: "Geisha Violet",
    cmyk: "25,75,9,0",
    hex: "#C15C92",
    pantone: "2385 C",
  },

  {
    colorId: 362,
    brandID: 3,
    code: "RV-214",
    name: "Violet",
    cmyk: "46,44,0,0",
    hex: "#9993C3",
    pantone: "2577 C",
  },

  {
    colorId: 363,
    brandID: 3,
    code: "RV-216",
    name: "Anonymous Violet",
    cmyk: "80, 85, 0, 21",
    hex: "#45347A",
    pantone: "5265 C",
  },

  {
    colorId: 364,
    brandID: 3,
    code: "RV-8",
    name: "Light Blue",
    cmyk: "60,0,4,0",
    hex: "#86cbec",
    pantone: "2985 C",
  },

  {
    colorId: 365,
    brandID: 3,
    code: "RV-30",
    name: "Electric Blue",
    cmyk: "100, 30, 0, 2",
    hex: "#007fb5",
    pantone: "3005 C",
  },

  {
    colorId: 366,
    brandID: 3,
    code: "RV-5005",
    name: "Dark Blue",
    cmyk: "100, 30, 0, 30",
    hex: "#00659D",
    pantone: "301 C",
  },

  {
    colorId: 367,
    brandID: 3,
    code: "RV-219",
    name: "Paris Green",
    cmyk: "55,0,40,0",
    hex: "#8BC8AA",
    pantone: "563 C",
  },

  {
    colorId: 368,
    brandID: 3,
    code: "RV-21",
    name: "Surgical Green",
    cmyk: "100, 0, 66, 0",
    hex: "#009473",
    pantone: "334 C",
  },

  {
    colorId: 369,
    brandID: 3,
    code: "RV-34",
    name: "Guacamole Green",
    cmyk: "55, 10, 82, 0",
    hex: "#91bd10",
    pantone: "360 C",
  },

  {
    colorId: 370,
    brandID: 3,
    code: "RV-5",
    name: "Lutecia Green",
    cmyk: "90, 12, 95, 40",
    hex: "#005900",
    pantone: "349 C",
  },

  {
    colorId: 371,
    brandID: 3,
    code: "RV-8002",
    name: "Toasted Brown",
    cmyk: "0, 52, 70, 55",
    hex: "#8A5531",
    pantone: "4705 C",
  },

  {
    colorId: 372,
    brandID: 3,
    code: "RV-9010",
    name: "White",
    cmyk: "",
    hex: "#FFFFFF",
    pantone: "",
  },

  {
    colorId: 373,
    brandID: 3,
    code: "RV-7040",
    name: "Pearl Grey",
    cmyk: "0, 0, 0, 65",
    hex: "#7B7C7E",
    pantone: "431 C",
  },

  {
    colorId: 374,
    brandID: 3,
    code: "RV-9011",
    name: "Black",
    cmyk: "",
    hex: "#1a171b",
    pantone: "",
  },

  {
    colorId: 375,
    brandID: 5,
    code: "RV-1021",
    name: "Light Yellow",
    cmyk: "0, 15, 100, 0",
    hex: "#ffe705",
    pantone: "108 U",
  },

  {
    colorId: 376,
    brandID: 5,
    code: "RV-2004",
    name: "Orange",
    cmyk: "0,49,66,0",
    hex: "#eb690d",
    pantone: "1645 U",
  },

  {
    colorId: 377,
    brandID: 5,
    code: "RV-3001",
    name: "Vivid Red",
    cmyk: "0, 100, 99, 4",
    hex: "#c90119",
    pantone: "1797 U",
  },

  {
    colorId: 378,
    brandID: 5,
    code: "RV-27",
    name: "Vampire Violet",
    cmyk: "100, 100, 0, 58",
    hex: "#3e2d65",
    pantone: "276 U",
  },

  {
    colorId: 379,
    brandID: 5,
    code: "RV-8",
    name: "Light Blue",
    cmyk: "",
    hex: "#86cbec",
    pantone: "2985 U",
  },

  {
    colorId: 380,
    brandID: 5,
    code: "RV-5013",
    name: "Navy Blue",
    cmyk: "100, 68, 0, 54",
    hex: "#002b51",
    pantone: "282 U",
  },

  {
    colorId: 381,
    brandID: 5,
    code: "RV-34",
    name: "Guacamole Green",
    cmyk: "56,0,78,0",
    hex: "#91bd10",
    pantone: "583 U",
  },

  {
    colorId: 382,
    brandID: 5,
    code: "RV-9011",
    name: "Black",
    cmyk: "",
    hex: "#000000",
    pantone: "",
  },

  {
    colorId: 383,
    brandID: 5,
    code: "RV-9010",
    name: "White",
    cmyk: "",
    hex: "#ffffff",
    pantone: "",
  },

  {
    colorId: 384,
    brandID: 5,
    code: "Silver",
    name: "Silver",
    cmyk: "",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 385,
    brandID: 6,
    code: "RV-9011",
    name: "Black",
    cmyk: "",
    hex: "#000000",
    pantone: "",
  },

  {
    colorId: 386,
    brandID: 6,
    code: "RV-9010",
    name: "White",
    cmyk: "",
    hex: "#FFFFFF",
    pantone: "",
  },

  {
    colorId: 387,
    brandID: 6,
    code: "Silver",
    name: "Silver",
    cmyk: "",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 388,
    brandID: 6,
    code: "RV-9011",
    name: "Black",
    cmyk: "",
    hex: "#000000",
    pantone: "",
  },

  {
    colorId: 389,
    brandID: 6,
    code: "Silver",
    name: "Silver",
    cmyk: "",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 390,
    brandID: 6,
    code: "RV-1021",
    name: "Light Yellow",
    cmyk: "9, 6, 96, 0",
    hex: "#ffea00",
    pantone: "",
  },

  {
    colorId: 391,
    brandID: 6,
    code: "RV-3001",
    name: "Light Red",
    cmyk: "26, 99, 78, 1",
    hex: "#c2173c",
    pantone: "",
  },

  {
    colorId: 392,
    brandID: 6,
    code: "RV-216",
    name: "Anonymous Violet",
    cmyk: "99, 100, 18, 0",
    hex: "#00217b",
    pantone: "",
  },

  {
    colorId: 393,
    brandID: 6,
    code: "RV-30",
    name: "Electric Blue",
    cmyk: "92, 63, 26, 0",
    hex: "#006393",
    pantone: "",
  },

  {
    colorId: 394,
    brandID: 6,
    code: "RV-219",
    name: "Paris Green",
    cmyk: "70, 7, 40, 0",
    hex: "#59b7ae",
    pantone: "",
  },

  {
    colorId: 395,
    brandID: 6,
    code: "RV-9010",
    name: "White",
    cmyk: "0, 0, 0, 0",
    hex: "#ffffff",
    pantone: "",
  },

  {
    colorId: 396,
    brandID: 10,
    code: "RV-9011",
    name: "Black",
    cmyk: "",
    hex: "#1a171b",
    pantone: "",
  },

  {
    colorId: 397,
    brandID: 10,
    code: "RV-9010",
    name: "Divinity White",
    cmyk: "",
    hex: "#FFFFFF",
    pantone: "",
  },

  {
    colorId: 398,
    brandID: 9,
    code: "Light Yellow",
    name: "Light Yellow",
    cmyk: "0, 1, 100, 0",
    hex: "#fedf00",
    pantone: "108 U",
  },

  {
    colorId: 399,
    brandID: 9,
    code: "Light Red",
    name: "Light Red",
    cmyk: "0,90,85,0",
    hex: "#ef3a38",
    pantone: "032 U",
  },

  {
    colorId: 400,
    brandID: 9,
    code: "Electric Blue",
    name: "Electric Blue",
    cmyk: "100, 32, 0, 23",
    hex: "#007FC4",
    pantone: "3005 C",
  },

  {
    colorId: 401,
    brandID: 9,
    code: "Silver",
    name: "Silver",
    cmyk: "",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 402,
    brandID: 9,
    code: "White",
    name: "White",
    cmyk: "0.0.0.0",
    hex: "#FFFFFF",
    pantone: "",
  },

  {
    colorId: 403,
    brandID: 9,
    code: "Black",
    name: "Black",
    cmyk: "0.0.0.100",
    hex: "#000000",
    pantone: "",
  },

  {
    colorId: 404,
    brandID: 12,
    code: "Black",
    name: "Black",
    cmyk: "80,91,84,74",
    hex: "#1b0409",
    pantone: "",
  },

  {
    colorId: 405,
    brandID: 12,
    code: "White",
    name: "White",
    cmyk: "0,0,0,0",
    hex: "#ffffff",
    pantone: "",
  },

  {
    colorId: 406,
    brandID: 12,
    code: "Red",
    name: "Red",
    cmyk: "10,98,100,0",
    hex: "#d71718",
    pantone: "",
  },

  {
    colorId: 407,
    brandID: 12,
    code: "Silver",
    name: "Silver",
    cmyk: "63,55,52,1",
    hex: "#727171",
    pantone: "",
  },

  {
    colorId: 408,
    brandID: 16,
    code: "N61",
    name: "Ride Yellow",
    cmyk: "",
    hex: "#F4D994",
    pantone: "",
  },

  {
    colorId: 409,
    brandID: 16,
    code: "N110",
    name: "Danger Yellow",
    cmyk: "",
    hex: "#EEC55C",
    pantone: "",
  },

  {
    colorId: 410,
    brandID: 16,
    code: "N155",
    name: "Cape Yellow",
    cmyk: "",
    hex: "#EDBD11",
    pantone: "",
  },

  {
    colorId: 411,
    brandID: 16,
    code: "N05",
    name: "Peseta Yellow",
    cmyk: "",
    hex: "#e2b31b",
    pantone: "",
  },

  {
    colorId: 412,
    brandID: 16,
    code: "N111",
    name: "Worn Yellow",
    cmyk: "",
    hex: "#caa72f",
    pantone: "",
  },

  {
    colorId: 413,
    brandID: 16,
    code: "N62",
    name: "asd Yellow",
    cmyk: "",
    hex: "#bca032",
    pantone: "",
  },

  {
    colorId: 414,
    brandID: 16,
    code: "N106",
    name: "Peach Orange",
    cmyk: "",
    hex: "#ffb35b",
    pantone: "",
  },

  {
    colorId: 415,
    brandID: 16,
    code: "N08",
    name: "Fetid Yellow",
    cmyk: "",
    hex: "#f79f24",
    pantone: "",
  },

  {
    colorId: 416,
    brandID: 16,
    code: "N107",
    name: "Toxic Yellow",
    cmyk: "",
    hex: "#db9637",
    pantone: "",
  },

  {
    colorId: 417,
    brandID: 16,
    code: "N67",
    name: "Terrenal Ocher",
    cmyk: "",
    hex: "#b38839",
    pantone: "",
  },

  {
    colorId: 418,
    brandID: 16,
    code: "N74",
    name: "Seafood Orange",
    cmyk: "",
    hex: "#f6bea7",
    pantone: "",
  },

  {
    colorId: 419,
    brandID: 16,
    code: "N71",
    name: "Horse Organge",
    cmyk: "",
    hex: "#ffa080",
    pantone: "",
  },

  {
    colorId: 420,
    brandID: 16,
    code: "N52",
    name: "Gazpacho Orange",
    cmyk: "",
    hex: "#e87966",
    pantone: "",
  },

  {
    colorId: 421,
    brandID: 16,
    code: "N72",
    name: "Rusty Orange",
    cmyk: "",
    hex: "#b4593c",
    pantone: "",
  },

  {
    colorId: 422,
    brandID: 16,
    code: "N162",
    name: "Coconut Brown",
    cmyk: "",
    hex: "#81554a",
    pantone: "",
  },

  {
    colorId: 423,
    brandID: 16,
    code: "N04",
    name: "Dromedary Beige",
    cmyk: "",
    hex: "#fdd1ac",
    pantone: "",
  },

  {
    colorId: 424,
    brandID: 16,
    code: "N140",
    name: "Pastel Orange",
    cmyk: "",
    hex: "#feab89",
    pantone: "",
  },

  {
    colorId: 425,
    brandID: 16,
    code: "N70",
    name: "Syrup Orange",
    cmyk: "",
    hex: "#f58555",
    pantone: "",
  },

  {
    colorId: 426,
    brandID: 16,
    code: "N09",
    name: "Without Orange",
    cmyk: "",
    hex: "#f26e35",
    pantone: "",
  },

  {
    colorId: 427,
    brandID: 16,
    code: "N10",
    name: "With Orange",
    cmyk: "",
    hex: "#e25d34",
    pantone: "",
  },

  {
    colorId: 428,
    brandID: 16,
    code: "N75",
    name: "Raspberry Red",
    cmyk: "",
    hex: "#c35669",
    pantone: "",
  },

  {
    colorId: 429,
    brandID: 16,
    code: "N76",
    name: "Palindromic Red",
    cmyk: "",
    hex: "#b04054",
    pantone: "",
  },

  {
    colorId: 430,
    brandID: 16,
    code: "N16",
    name: "Blood clot Red",
    cmyk: "",
    hex: "#a33d41",
    pantone: "",
  },

  {
    colorId: 431,
    brandID: 16,
    code: "N152",
    name: "Pure Red",
    cmyk: "",
    hex: "#b23539",
    pantone: "",
  },

  {
    colorId: 432,
    brandID: 16,
    code: "N015",
    name: "Calimocho Red",
    cmyk: "",
    hex: "#8f363c",
    pantone: "",
  },

  {
    colorId: 433,
    brandID: 16,
    code: "N139",
    name: "Wine Red",
    cmyk: "",
    hex: "#684142",
    pantone: "",
  },

  {
    colorId: 434,
    brandID: 16,
    code: "N11",
    name: "Picky Pink",
    cmyk: "",
    hex: "#ebbdc7",
    pantone: "",
  },

  {
    colorId: 435,
    brandID: 16,
    code: "N136",
    name: "Blush Pink",
    cmyk: "",
    hex: "#de8aa1",
    pantone: "",
  },

  {
    colorId: 436,
    brandID: 16,
    code: "N53",
    name: "Light Magenta",
    cmyk: "",
    hex: "#cd80aa",
    pantone: "",
  },

  {
    colorId: 437,
    brandID: 16,
    code: "N154",
    name: "Sharp Magenta",
    cmyk: "",
    hex: "#bf5a82",
    pantone: "",
  },

  {
    colorId: 438,
    brandID: 16,
    code: "N12",
    name: "Piggy Pink",
    cmyk: "",
    hex: "#dbaca5",
    pantone: "",
  },

  {
    colorId: 439,
    brandID: 16,
    code: "N78",
    name: "Smoked Pink",
    cmyk: "",
    hex: "#b36c7c",
    pantone: "",
  },

  {
    colorId: 440,
    brandID: 16,
    code: "N79",
    name: "Distant Red",
    cmyk: "",
    hex: "#a15a68",
    pantone: "",
  },

  {
    colorId: 441,
    brandID: 16,
    code: "N80",
    name: "Congested Red",
    cmyk: "",
    hex: "#874c52",
    pantone: "",
  },

  {
    colorId: 442,
    brandID: 16,
    code: "N18",
    name: "Scab Red",
    cmyk: "",
    hex: "#714544",
    pantone: "",
  },

  {
    colorId: 443,
    brandID: 16,
    code: "N13",
    name: "Placenta Pink",
    cmyk: "",
    hex: "#bd7e8f",
    pantone: "",
  },

  {
    colorId: 444,
    brandID: 16,
    code: "N135",
    name: "Deformation Pink",
    cmyk: "",
    hex: "#a77a7d",
    pantone: "",
  },

  {
    colorId: 445,
    brandID: 16,
    code: "N17",
    name: "Cane Red",
    cmyk: "",
    hex: "#895b5d",
    pantone: "",
  },

  {
    colorId: 446,
    brandID: 16,
    code: "N19",
    name: "Reddish Red",
    cmyk: "",
    hex: "#5a4848",
    pantone: "",
  },

  {
    colorId: 447,
    brandID: 16,
    code: "N134",
    name: "Dull Violet",
    cmyk: "",
    hex: "#52474b",
    pantone: "",
  },

  {
    colorId: 448,
    brandID: 16,
    code: "N34",
    name: "Funky Violet",
    cmyk: "",
    hex: "#c4b7cb",
    pantone: "",
  },

  {
    colorId: 449,
    brandID: 16,
    code: "N164",
    name: "Pleasure Violet",
    cmyk: "",
    hex: "#afa3b7",
    pantone: "",
  },

  {
    colorId: 450,
    brandID: 16,
    code: "N130",
    name: "Vatican Violet",
    cmyk: "",
    hex: "#9981a3",
    pantone: "",
  },

  {
    colorId: 451,
    brandID: 16,
    code: "N36",
    name: "Aricose Violet",
    cmyk: "",
    hex: "#866c93",
    pantone: "",
  },

  {
    colorId: 452,
    brandID: 16,
    code: "N131",
    name: "Joker Purple",
    cmyk: "",
    hex: "#75627e",
    pantone: "",
  },

  {
    colorId: 453,
    brandID: 16,
    code: "N129",
    name: "Candy Violet",
    cmyk: "",
    hex: "#b9acc6",
    pantone: "",
  },

  {
    colorId: 454,
    brandID: 16,
    code: "N35",
    name: "Hippie Purple",
    cmyk: "",
    hex: "#a193b4",
    pantone: "",
  },

  {
    colorId: 455,
    brandID: 16,
    code: "N37",
    name: "Bruise Violet",
    cmyk: "",
    hex: "#705e84",
    pantone: "",
  },

  {
    colorId: 456,
    brandID: 16,
    code: "N83",
    name: "Paramecia Violet",
    cmyk: "",
    hex: "#654f7e",
    pantone: "",
  },

  {
    colorId: 457,
    brandID: 16,
    code: "N38",
    name: "Amethist Violet",
    cmyk: "",
    hex: "#51435c",
    pantone: "",
  },

  {
    colorId: 458,
    brandID: 16,
    code: "N126",
    name: "Celeste Blue",
    cmyk: "",
    hex: "#82c9db",
    pantone: "",
  },

  {
    colorId: 459,
    brandID: 16,
    code: "N27",
    name: "Trade Blue",
    cmyk: "",
    hex: "#5db7dc",
    pantone: "",
  },

  {
    colorId: 460,
    brandID: 16,
    code: "N28",
    name: "Osmosis Blue",
    cmyk: "",
    hex: "#4390bc",
    pantone: "",
  },

  {
    colorId: 461,
    brandID: 16,
    code: "N29",
    name: "Smurf Blue",
    cmyk: "",
    hex: "#0496c7",
    pantone: "",
  },

  {
    colorId: 462,
    brandID: 16,
    code: "N151",
    name: "Just Blue",
    cmyk: "",
    hex: "#17679a",
    pantone: "",
  },

  {
    colorId: 463,
    brandID: 16,
    code: "N59",
    name: "Electro Blue",
    cmyk: "",
    hex: "#384764",
    pantone: "",
  },

  {
    colorId: 464,
    brandID: 16,
    code: "N166",
    name: "Quiet Blue",
    cmyk: "",
    hex: "#93c8e6",
    pantone: "",
  },

  {
    colorId: 465,
    brandID: 16,
    code: "N128",
    name: "Baby Blue",
    cmyk: "",
    hex: "#83b5d6",
    pantone: "",
  },

  {
    colorId: 466,
    brandID: 16,
    code: "N33",
    name: "Ozone Blue",
    cmyk: "",
    hex: "#649bc7",
    pantone: "",
  },

  {
    colorId: 467,
    brandID: 16,
    code: "N32",
    name: "Yin Blue",
    cmyk: "",
    hex: "#5c789d",
    pantone: "",
  },

  {
    colorId: 468,
    brandID: 16,
    code: "N131",
    name: "Tattoo Blue",
    cmyk: "",
    hex: "#505978",
    pantone: "",
  },

  {
    colorId: 469,
    brandID: 16,
    code: "N26",
    name: "Cooling Blue",
    cmyk: "",
    hex: "#a9dbdc",
    pantone: "",
  },

  {
    colorId: 470,
    brandID: 16,
    code: "N124",
    name: "Cristaline Blue",
    cmyk: "",
    hex: "#8fcecd",
    pantone: "",
  },

  {
    colorId: 471,
    brandID: 16,
    code: "N125",
    name: "Jellyfish Blue",
    cmyk: "",
    hex: "#5fb1af",
    pantone: "",
  },

  {
    colorId: 472,
    brandID: 16,
    code: "N150",
    name: "Surgey Blue",
    cmyk: "",
    hex: "#4b8f92",
    pantone: "",
  },

  {
    colorId: 473,
    brandID: 16,
    code: "N58",
    name: "Turquoise Blue",
    cmyk: "",
    hex: "#376268",
    pantone: "",
  },

  {
    colorId: 474,
    brandID: 16,
    code: "N167",
    name: "Chicle Menta Green",
    cmyk: "",
    hex: "#8acaba",
    pantone: "",
  },

  {
    colorId: 475,
    brandID: 16,
    code: "N168",
    name: "Celadon Green",
    cmyk: "",
    hex: "#64bca4",
    pantone: "",
  },

  {
    colorId: 476,
    brandID: 16,
    code: "N121",
    name: "Transgenic Green",
    cmyk: "",
    hex: "#40a480",
    pantone: "",
  },

  {
    colorId: 477,
    brandID: 16,
    code: "N169",
    name: "Alcarraza Green",
    cmyk: "",
    hex: "#368a69",
    pantone: "",
  },

  {
    colorId: 478,
    brandID: 16,
    code: "N56",
    name: "Cucumber Green",
    cmyk: "",
    hex: "#3a4946",
    pantone: "",
  },

  {
    colorId: 479,
    brandID: 16,
    code: "N20",
    name: "Digit Green",
    cmyk: "",
    hex: "#c9e58e",
    pantone: "",
  },

  {
    colorId: 480,
    brandID: 16,
    code: "N93",
    name: "Suede Green",
    cmyk: "",
    hex: "#a9c36c",
    pantone: "",
  },

  {
    colorId: 481,
    brandID: 16,
    code: "N57",
    name: "Laser Green",
    cmyk: "",
    hex: "#8cb042",
    pantone: "",
  },

  {
    colorId: 482,
    brandID: 16,
    code: "N24",
    name: "Roquefort Green",
    cmyk: "",
    hex: "#187d55",
    pantone: "",
  },

  {
    colorId: 483,
    brandID: 16,
    code: "N122",
    name: "Monte Green",
    cmyk: "",
    hex: "#3a6753",
    pantone: "",
  },

  {
    colorId: 484,
    brandID: 16,
    code: "N90",
    name: "Outdoor Green",
    cmyk: "",
    hex: "#88b789",
    pantone: "",
  },

  {
    colorId: 485,
    brandID: 16,
    code: "N23",
    name: "Moss Green",
    cmyk: "",
    hex: "#5c9d5b",
    pantone: "",
  },

  {
    colorId: 486,
    brandID: 16,
    code: "N153",
    name: "Eco Green",
    cmyk: "",
    hex: "#508e4b",
    pantone: "",
  },

  {
    colorId: 487,
    brandID: 16,
    code: "N120",
    name: "Seaweed Green",
    cmyk: "",
    hex: "#658462",
    pantone: "",
  },

  {
    colorId: 488,
    brandID: 16,
    code: "N91",
    name: "Jungletilt Green",
    cmyk: "",
    hex: "#5e745f",
    pantone: "",
  },

  {
    colorId: 489,
    brandID: 16,
    code: "N88",
    name: "Alternative Green",
    cmyk: "",
    hex: "#3f5d55",
    pantone: "",
  },

  {
    colorId: 490,
    brandID: 16,
    code: "N51",
    name: "Nuclear Yellow",
    cmyk: "",
    hex: "#dedd37",
    pantone: "",
  },

  {
    colorId: 491,
    brandID: 16,
    code: "N69",
    name: "Acid Green",
    cmyk: "",
    hex: "#cad338",
    pantone: "",
  },

  {
    colorId: 492,
    brandID: 16,
    code: "N21",
    name: "Indoor Green",
    cmyk: "",
    hex: "#b5c44f",
    pantone: "",
  },

  {
    colorId: 493,
    brandID: 16,
    code: "N22",
    name: "Wisha Green",
    cmyk: "",
    hex: "#b0a667",
    pantone: "",
  },

  {
    colorId: 494,
    brandID: 16,
    code: "N117",
    name: "Sabannah Green",
    cmyk: "",
    hex: "#858a5f",
    pantone: "",
  },

  {
    colorId: 495,
    brandID: 16,
    code: "N118",
    name: "Camuflage Green",
    cmyk: "",
    hex: "#79844d",
    pantone: "",
  },

  {
    colorId: 496,
    brandID: 16,
    code: "N114",
    name: "Pale Green",
    cmyk: "",
    hex: "#ccbb87",
    pantone: "",
  },

  {
    colorId: 497,
    brandID: 16,
    code: "N112",
    name: "Waste Green",
    cmyk: "",
    hex: "#98873f",
    pantone: "",
  },

  {
    colorId: 498,
    brandID: 16,
    code: "N63",
    name: "Espiritual Green",
    cmyk: "",
    hex: "#807942",
    pantone: "",
  },

  {
    colorId: 499,
    brandID: 16,
    code: "N115",
    name: "Olive Green",
    cmyk: "",
    hex: "#8f8967",
    pantone: "",
  },

  {
    colorId: 500,
    brandID: 16,
    code: "N119",
    name: "Militar Green",
    cmyk: "",
    hex: "#686c5b",
    pantone: "",
  },

  {
    colorId: 501,
    brandID: 16,
    code: "N02",
    name: "Dermos Beige",
    cmyk: "",
    hex: "#daceb8",
    pantone: "",
  },

  {
    colorId: 502,
    brandID: 16,
    code: "N65",
    name: "Pistil Green",
    cmyk: "",
    hex: "#a88e69",
    pantone: "",
  },

  {
    colorId: 503,
    brandID: 16,
    code: "N66",
    name: "Mimetized Green",
    cmyk: "",
    hex: "#948568",
    pantone: "",
  },

  {
    colorId: 504,
    brandID: 16,
    code: "N94",
    name: "Cuco Green",
    cmyk: "",
    hex: "#8a6f44",
    pantone: "",
  },

  {
    colorId: 505,
    brandID: 16,
    code: "N95",
    name: "Bitgreen Ocher",
    cmyk: "",
    hex: "#796646",
    pantone: "",
  },

  {
    colorId: 506,
    brandID: 16,
    code: "N003",
    name: "Rope Cream",
    cmyk: "",
    hex: "#e4c6a4",
    pantone: "",
  },

  {
    colorId: 507,
    brandID: 16,
    code: "N64",
    name: "Oats Ocher",
    cmyk: "",
    hex: "#c89d6a",
    pantone: "",
  },

  {
    colorId: 508,
    brandID: 16,
    code: "N108",
    name: "Desert Yellow",
    cmyk: "",
    hex: "#c69351",
    pantone: "",
  },

  {
    colorId: 509,
    brandID: 16,
    code: "N48",
    name: "Era Ocher",
    cmyk: "",
    hex: "#b68040",
    pantone: "",
  },

  {
    colorId: 510,
    brandID: 16,
    code: "N109",
    name: "Toffee Brown",
    cmyk: "",
    hex: "#a47944",
    pantone: "",
  },

  {
    colorId: 511,
    brandID: 16,
    code: "N68",
    name: "Sponge Yellow",
    cmyk: "",
    hex: "#fecc8d",
    pantone: "",
  },

  {
    colorId: 512,
    brandID: 16,
    code: "N105",
    name: "Fresh Orange",
    cmyk: "",
    hex: "#edbb86",
    pantone: "",
  },

  {
    colorId: 513,
    brandID: 16,
    code: "N06",
    name: "Nécora Yellow",
    cmyk: "",
    hex: "#e6ac62",
    pantone: "",
  },

  {
    colorId: 514,
    brandID: 16,
    code: "N104",
    name: "Knoll Ocher",
    cmyk: "",
    hex: "#c69668",
    pantone: "",
  },

  {
    colorId: 515,
    brandID: 16,
    code: "N45",
    name: "Brick Brown",
    cmyk: "",
    hex: "#a16c42",
    pantone: "",
  },

  {
    colorId: 516,
    brandID: 16,
    code: "N98",
    name: "Without Skin",
    cmyk: "",
    hex: "#c4b2b0",
    pantone: "",
  },

  {
    colorId: 517,
    brandID: 16,
    code: "N143",
    name: "Beech Brown",
    cmyk: "",
    hex: "#ad8d81",
    pantone: "",
  },

  {
    colorId: 518,
    brandID: 16,
    code: "N145",
    name: "Rock Brown",
    cmyk: "",
    hex: "#7f6d69",
    pantone: "",
  },

  {
    colorId: 519,
    brandID: 16,
    code: "N144",
    name: "Pure Brown",
    cmyk: "",
    hex: "#594343",
    pantone: "",
  },

  {
    colorId: 520,
    brandID: 16,
    code: "N97",
    name: "Bonbon Brown",
    cmyk: "",
    hex: "#514946",
    pantone: "",
  },

  {
    colorId: 521,
    brandID: 16,
    code: "N159",
    name: "Diet Brown",
    cmyk: "",
    hex: "#d5c3af",
    pantone: "",
  },

  {
    colorId: 522,
    brandID: 16,
    code: "N157",
    name: "Dune Brown",
    cmyk: "",
    hex: "#c79d87",
    pantone: "",
  },

  {
    colorId: 523,
    brandID: 16,
    code: "N161",
    name: "Camello Brown",
    cmyk: "",
    hex: "#b2856e",
    pantone: "",
  },

  {
    colorId: 524,
    brandID: 16,
    code: "N73",
    name: "Roof Brown",
    cmyk: "",
    hex: "#8e563f",
    pantone: "",
  },

  {
    colorId: 525,
    brandID: 16,
    code: "N160",
    name: "Box Brown",
    cmyk: "",
    hex: "#c7a894",
    pantone: "",
  },

  {
    colorId: 526,
    brandID: 16,
    code: "N141",
    name: "Clay Brown",
    cmyk: "",
    hex: "#c48365",
    pantone: "",
  },

  {
    colorId: 527,
    brandID: 16,
    code: "N158",
    name: "Combi Brown",
    cmyk: "",
    hex: "#a9765b",
    pantone: "",
  },

  {
    colorId: 528,
    brandID: 16,
    code: "N47",
    name: "Freckle Brown",
    cmyk: "",
    hex: "#795849",
    pantone: "",
  },

  {
    colorId: 529,
    brandID: 16,
    code: "N156",
    name: "Queen Purple",
    cmyk: "",
    hex: "#9c4981",
    pantone: "",
  },

  {
    colorId: 530,
    brandID: 16,
    code: "N54",
    name: "Potion Purple",
    cmyk: "",
    hex: "#8a486c",
    pantone: "",
  },

  {
    colorId: 531,
    brandID: 16,
    code: "N137",
    name: "Party Violet",
    cmyk: "",
    hex: "#7b4e6b",
    pantone: "",
  },

  {
    colorId: 532,
    brandID: 16,
    code: "N40",
    name: "Smoke Grey",
    cmyk: "",
    hex: "#c2c4c3",
    pantone: "",
  },

  {
    colorId: 533,
    brandID: 16,
    code: "N41",
    name: "Alergic Grey",
    cmyk: "",
    hex: "#9a9e9f",
    pantone: "",
  },

  {
    colorId: 534,
    brandID: 16,
    code: "N170",
    name: "Gargoyle Grey",
    cmyk: "",
    hex: "#7c8183",
    pantone: "",
  },

  {
    colorId: 535,
    brandID: 16,
    code: "N42",
    name: "Menhir Grey",
    cmyk: "",
    hex: "#696e72",
    pantone: "",
  },

  {
    colorId: 536,
    brandID: 16,
    code: "N60",
    name: "Magnum Grey",
    cmyk: "",
    hex: "#545559",
    pantone: "",
  },

  {
    colorId: 537,
    brandID: 16,
    code: "N147",
    name: "Moon Grey",
    cmyk: "",
    hex: "#afaab1",
    pantone: "",
  },

  {
    colorId: 538,
    brandID: 16,
    code: "N100",
    name: "Distant Grey",
    cmyk: "",
    hex: "#8a848c",
    pantone: "",
  },

  {
    colorId: 539,
    brandID: 16,
    code: "N43",
    name: "Baday Grey",
    cmyk: "",
    hex: "#686169",
    pantone: "",
  },

  {
    colorId: 540,
    brandID: 16,
    code: "N148",
    name: "Dolphin Grey",
    cmyk: "",
    hex: "#66636a",
    pantone: "",
  },

  {
    colorId: 541,
    brandID: 16,
    code: "N01",
    name: "Black",
    cmyk: "",
    hex: "#000",
    pantone: "",
  },

  {
    colorId: 542,
    brandID: 16,
    code: "N50",
    name: "White",
    cmyk: "",
    hex: "#ffffff",
    pantone: "",
  },

  {
    colorId: 543,
    brandID: 16,
    code: "N501",
    name: "Transparent White",
    cmyk: "",
    hex: "#eeeee2",
    pantone: "",
  },

  {
    colorId: 544,
    brandID: 16,
    code: "N502",
    name: "Transparent Black",
    cmyk: "",
    hex: "#56534e",
    pantone: "",
  },

  {
    colorId: 545,
    brandID: 16,
    code: "N177",
    name: "Gold",
    cmyk: "",
    hex: "#d5c07f",
    pantone: "",
  },

  {
    colorId: 546,
    brandID: 16,
    code: "N49",
    name: "Silver",
    cmyk: "",
    hex: "#dddddd",
    pantone: "",
  },

  {
    colorId: 547,
    brandID: 16,
    code: "S553",
    name: "Matt Varnish",
    cmyk: "",
    hex: "#efefef",
    pantone: "",
  },

  {
    colorId: 548,
    brandID: 16,
    code: "S551",
    name: "Gloss Varnish",
    cmyk: "",
    hex: "#f9f9f9",
    pantone: "",
  },

  {
    colorId: 549,
    brandID: 16,
    code: "N171",
    name: "Fluor Yellow",
    cmyk: "",
    hex: "#e3e82a",
    pantone: "",
  },

  {
    colorId: 550,
    brandID: 16,
    code: "N172",
    name: "Fluor Orange",
    cmyk: "",
    hex: "#fe906d",
    pantone: "",
  },

  {
    colorId: 551,
    brandID: 16,
    code: "N173",
    name: "Fluor Red",
    cmyk: "",
    hex: "#ff7376",
    pantone: "",
  },

  {
    colorId: 552,
    brandID: 16,
    code: "N174",
    name: "Fluor Cherry",
    cmyk: "",
    hex: "#ff41b5",
    pantone: "",
  },

  {
    colorId: 553,
    brandID: 16,
    code: "N175",
    name: "Fluor Blue",
    cmyk: "",
    hex: "#0083cb",
    pantone: "",
  },

  {
    colorId: 554,
    brandID: 16,
    code: "N176",
    name: "Fluor Green",
    cmyk: "",
    hex: "#38d530",
    pantone: "",
  },

  {
    colorId: 555,
    brandID: 17,
    code: "",
    name: "Matt Silver",
    cmyk: "",
    hex: "#a7a7a7",
    pantone: "",
  },

  {
    colorId: 556,
    brandID: 17,
    code: "",
    name: "Matt Black",
    cmyk: "",
    hex: "#000000",
    pantone: "",
  },
];
