import React from 'react';
import { Hero } from './Hero';

export default function Home() {
  return (
    <div>
      <Hero />
    </div>
  );
}
