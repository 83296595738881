import { FaFacebook, FaInstagram, FaYoutube, FaWhatsapp } from "react-icons/fa";

import { AiOutlineMail } from "react-icons/ai";

export const social = [
  {
    id: 1,
    name: "facebook",
    url: "https://www.facebook.com/oldschoolestudio",
    icon: FaFacebook,
    color: "#5297e4",
  },

  {
    id: 2,
    name: "instagram",
    url: "https://www.instagram.com/oldschoolgaia/",
    icon: FaInstagram,
    color: "#ff8585",
  },

  {
    id: 3,
    name: "youtube",
    url: "https://www.youtube.com/channel/UCrctXtllpHQCF_w3DK6RkJQ",
    icon: FaYoutube,
    color: "#ff1926",
  },

  {
    id: 4,
    name: "whatsapp",
    url: "https://wa.me/351917274301",
    icon: FaWhatsapp,
    color: "#32d934",
  },

  {
    id: 5,
    name: "email",
    url: "mailto:geral@oldschool.com.pt",
    icon: AiOutlineMail,
    color: "#154c79",
  },
];
